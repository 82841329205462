/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TableCell } from "@material-ui/core";
import moment from "moment";

const Reminder = ({ date, interval }) => {
  const [rem, setRem] = useState(moment(date).fromNow())
  useEffect(() => {
    const f = setInterval(() => {
      setRem(moment(date).fromNow())
    }, interval * 1000);
    return () => clearInterval(f);
  }, [date]);
  return <TableCell align="left">{rem}</TableCell>;
}

export default Reminder;