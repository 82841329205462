/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { cround } from "../utils/numbers";
import { useTiposVentas } from "./useTiposVentas";

export const ventaValues = {
  fecha: "",
  factura: "",
  autorizacion: "",
  nit: "",
  complemento: "",
  razon_social: "",
  importe: "",
  ice: "",
  iehd: "",
  ipj: "",
  tasas: "",
  otros_no_dfiva: "",
  exportaciones: "",
  tasa_cero: "",
  subtotal: "",
  descuentos: "",
  gift_card: "",
  base_dfiva: "",
  dfiva: "",
  estado: "V",
  codigo_control: "",
  id_sucursal: "",
  tipo_venta: "0",
  day: "01",
};

export const useVenta = (fecha, empresa) => {
  const [defaultValues, setDefaultValues] = useState({
    ...ventaValues,
    fecha,
    id_sucursal: empresa.sucursal,
  });
  const [venta, setVenta] = useState({
    ...ventaValues,
    fecha,
    id_sucursal: empresa.sucursal,
  });

  const { tipo, fields, changeTipo } = useTiposVentas();

  useEffect(() => {
    setVenta({
      ...venta,
      ice: fields.ice.valueDefault,
      iehd: fields.iehd.valueDefault,
      ipj: fields.ipj.valueDefault,
      otros_no_dfiva: fields.otros_no_dfiva.valueDefault,
      tasas: fields.tasas.valueDefault,
      tasa_cero: fields.tasa_cero.valueDefault,
      exportaciones: fields.exportaciones.valueDefault,
      gift_card: fields.gift_card.valueDefault,
      descuentos: fields.descuentos.valueDefault,
      codigo_control: fields.codigo_control.valueDefault,
    });
    setDefaultValues({
      ...venta,
      ice: fields.ice.valueDefault,
      iehd: fields.iehd.valueDefault,
      ipj: fields.ipj.valueDefault,
      otros_no_dfiva: fields.otros_no_dfiva.valueDefault,
      tasas: fields.tasas.valueDefault,
      tasa_cero: fields.tasa_cero.valueDefault,
      exportaciones: fields.exportaciones.valueDefault,
      gift_card: fields.gift_card.valueDefault,
      descuentos: fields.descuentos.valueDefault,
      codigo_control: fields.codigo_control.valueDefault,
    });
  }, [fields]);

  useEffect(() => {
    setVenta({
      ...venta,
      id_sucursal: empresa?.sucursal,
    });
  }, [empresa?.sucursal]);

  useEffect(() => {
    const {
      importe,
      ice,
      iehd,
      ipj,
      tasas,
      otros_no_dfiva,
      exportaciones,
      tasa_cero,
    } = venta;
    let subtotal =
      importe -
      ice -
      iehd -
      ipj -
      tasas -
      otros_no_dfiva -
      exportaciones -
      tasa_cero;
    subtotal = cround(subtotal);
    setVenta({
      ...venta,
      subtotal,
    });
  }, [
    venta.importe,
    venta.ice,
    venta.iehd,
    venta.ipj,
    venta.tasas,
    venta.otros_no_cfiva,
    venta.exportaciones,
    venta.tasa_cero,
  ]);

  useEffect(() => {
    const { subtotal, descuentos, gift_card } = venta;
    const base_dfiva = cround(subtotal - descuentos - gift_card);
    const dfiva = cround(base_dfiva * 0.13);
    setVenta({
      ...venta,
      base_dfiva,
      dfiva,
    });
  }, [venta.subtotal, venta.descuentos, venta.gift_card]);

  const resetVenta = (incrementar = false) => {
    const i = incrementar ? 1 : 0;
    setVenta({
      ...defaultValues,
      id_sucursal: empresa.sucursal,
      fecha: venta.fecha,
      autorizacion: venta.autorizacion,
      day: venta.day,
      factura: (parseInt(venta.factura || 0) + i).toString(),
      importe: "",
      subtotal: "",
      base_dfiva: "",
      dfiva: "",
      nit: "",
      complemento: "",
      razon_social: "",
    });
  };

  return {
    venta,
    setVenta,
    resetVenta,
    tipos: {
      fields,
      changeTipo,
      tipo,
    },
  };
};
