import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import StoreIcon from "@material-ui/icons/Store";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { useSelector } from "react-redux";

const MainListItems = () => {
  const history = useHistory();
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);

  return (
    <div className="dashboard">
      <ListItem
        button
        disabled={!empresa.sucursales.length}
        onClick={() => history.push("/empresa/compras")}
      >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Compras" />
      </ListItem>
      <ListItem
        button
        disabled={!empresa.sucursales.length}
        onClick={() => history.push("/empresa/ventas")}
      >
        <ListItemIcon>
          <TrendingUpIcon />
        </ListItemIcon>
        <ListItemText primary="Ventas" />
      </ListItem>
      <ListItem button onClick={() => history.push("/empresa/sucursales")}>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="Sucursales" />
      </ListItem>
    </div>
  );
};

export default MainListItems;
