import { useSelector } from "react-redux";
import FormModal from "./FormModal";
import { Button } from "@material-ui/core";
import { parse } from "papaparse";
import { useRef, useState } from "react";
import "./styles.css";
import moment from "moment";
import { useRequest } from "../../hooks/useRequest";
import FacturasList from "../atoms/FacturasLIst";

const estados = {
  ANULADA: "A",
  VALIDA: "V",
  "EMITIDA EN CONTINGENCIA": "C",
  "LIBRE CONSIGNACIÓN": "L",
};

const CSVReader = ({
  open,
  handleClose,
  tipo = "ventas",
  onSuccess = () => {},
}) => {
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const [highlighted, setHighlighted] = useState(false);
  const refInputFile = useRef();
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState([]);
  const [accepts, setAccepts] = useState([]);
  const [data, setData] = useState([]);
  const { request } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/${tipo}/many`,
    "post",
    ({ accepts, errors }) => {
      setErrors(errors);
      setAccepts(accepts);
      setData([]);
      setFileName([]);
      onSuccess();
    }
  );

  const formatCompras = (compras) => {
    const newCompras = [];
    compras.forEach((compra, index) => {
      if (index + 1 !== compras.length) {
        let newCompra = {};
        const fields = Object.values(compra);
        newCompra.nit = fields[1];
        newCompra.razon_social = fields[2];
        newCompra.autorizacion = fields[3];
        newCompra.factura = fields[4];
        newCompra.dui = fields[5];
        newCompra.fecha = moment(fields[6], "DD/MM/YYYY").format("YYYY-MM-DD");
        newCompra.importe = fields[7];
        newCompra.ice = fields[8];
        newCompra.iehd = fields[9];
        newCompra.ipj = fields[10];
        newCompra.tasas = fields[11];
        newCompra.otros_no_cfiva = fields[12];
        newCompra.exentos = fields[13];
        newCompra.tasa_cero = fields[14];
        newCompra.subtotal = fields[15];
        newCompra.descuentos = fields[16];
        newCompra.gift_card = fields[17];
        newCompra.base_cfiva = fields[18];
        newCompra.cfiva = fields[19];
        newCompra.tipo_compra = fields[20] ? fields[20] : "1";
        newCompra.codigo_control = fields[21] ? fields[21] : "0";
        newCompra.id_sucursal = empresa.sucursal;
        newCompras.push(newCompra);
      }
    });
    setData(newCompras);
  };

  const formatVentas = (ventas) => {
    const newVentas = [];
    ventas.forEach((venta, index) => {
      if (index + 1 !== ventas.length) {
        let newVenta = {};
        const fields = Object.values(venta);
        newVenta.fecha = moment(fields[1], "DD/MM/YYYY").format("YYYY-MM-DD");
        newVenta.factura = fields[2];
        newVenta.autorizacion = fields[3];
        newVenta.nit = fields[4];
        newVenta.complemento = fields[5];
        newVenta.razon_social = fields[6];
        newVenta.importe = fields[7];
        newVenta.ice = fields[8];
        newVenta.iehd = fields[9];
        newVenta.ipj = fields[10];
        newVenta.tasas = fields[11];
        newVenta.otros_no_dfiva = fields[12];
        newVenta.exportaciones = fields[13];
        newVenta.tasa_cero = fields[14];
        newVenta.subtotal = fields[15];
        newVenta.descuentos = fields[16];
        newVenta.gift_card = fields[17];
        newVenta.base_dfiva = fields[18];
        newVenta.dfiva = fields[19];
        newVenta.estado = estados[fields[20]];
        newVenta.codigo_control = fields[21] ? fields[21] : "0";
        newVenta.tipo_venta = ["OTROS", "GIFT CARD"].indexOf(fields[22]);
        newVenta.id_sucursal = empresa.sucursal;
        newVentas.push(newVenta);
      }
    });
    setData(newVentas);
  };
  const handleOnClose = (e, r) => {
    if (!r) {
      handleClose();
      setErrors([]);
      setAccepts([]);
      setData([]);
      setFileName([]);
    }
  };
  return (
    <FormModal
      open={open}
      handleClose={handleOnClose}
      width="md"
      // fullWidth
    >
      <FormModal.Title>
        CARGAR CSV DE{tipo === "ventas" ? " VENTAS" : " COMPRAS"}
      </FormModal.Title>
      <FormModal.Body dividers className="csv-dialog-container">
        <div className="csv-container">
          <div
            onClick={() => refInputFile.current.click()}
            className={!highlighted ? "csv-area" : "csv-area-hover"}
            onDragEnter={() => {
              setHighlighted(true);
            }}
            onDragLeave={() => {
              setHighlighted(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              setHighlighted(false);
              setFileName(e.dataTransfer.files[0].name);
              Array.from(e.dataTransfer.files)
                .filter((file) => file.type === "application/vnd.ms-excel")
                .forEach(async (file) => {
                  const text = await file.text();
                  const result = parse(text, { header: true });
                  if (tipo === "ventas") {
                    formatVentas(result.data);
                  } else {
                    formatCompras(result.data);
                  }
                });
            }}
          >
            <div className="csv-texts">
              <div className="csv-text">ARRASTRAR EL ARCHIVO AQUI</div>
              <div className="csv-name">{fileName}</div>
            </div>
          </div>
          <input
            id="fileCSV"
            ref={refInputFile}
            type="file"
            onChange={(event) => {
              setFileName(event.target.files[0].name);
              Array.from(event.target.files)
                .filter((file) =>
                  ["application/vnd.ms-excel", "text/csv"].includes(file.type)
                )
                .forEach(async (file) => {
                  const text = await file.text();
                  const result = parse(text, { header: true });
                  if (tipo === "ventas") {
                    formatVentas(result.data);
                  } else {
                    formatCompras(result.data);
                  }
                });
              event.target.value = "";
            }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
        {(accepts.length > 0 || errors.length > 0) && (
          <div className="csv-result">
            <h4>ACEPTADOS</h4>
            <FacturasList facturas={accepts} tipo="aceptados" />
            <h4>RECHAZADOS</h4>
            <FacturasList facturas={errors} tipo="rechazados" />
          </div>
        )}
      </FormModal.Body>
      <FormModal.Actions>
        <Button
          onClick={() => {
            const token = localStorage.getItem("token");
            request("data", { [tipo]: data }, token);
          }}
          color="primary"
          disabled={!data.length}
        >
          PROCESAR
        </Button>
        <Button onClick={handleOnClose} color="primary">
          CERRAR
        </Button>
      </FormModal.Actions>
    </FormModal>
  );
};

export default CSVReader;
