import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import HeaderSucursales from "../HeaderSucursales";
import { UPDATE } from "../../../store/types";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function TableSucursales({ prepareAction }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("correlativo");
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <HeaderSucursales
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={empresa?.sucursales?.length}
            />
            <TableBody>
              {stableSort(
                empresa.sucursales,
                getComparator(order, orderBy)
              ).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index + 1}>
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.codigo}</TableCell>
                    <TableCell align="center">{row.zona}</TableCell>
                    <TableCell align="center">{row.direccion}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => prepareAction(UPDATE, { ...row })}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton
                        size="small"
                        onClick={() => prepareAction(DELETE, { ...row })}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  paper: {
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    height: "100%",
  },
  tableContainer: {
    // height: "calc(100%)",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
