import React, { useState } from 'react';
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  { id: "correlativo", numeric: true, disablePadding: true, label: "N", sorteable: false, witdh: '40px', show: true, fixed: true },
  {
    id: "modificado",
    numeric: false,
    disablePadding: true,
    label: "MODIFICADO",
    sorteable: true,
    width: "160px",
    show: true,
    fixed: true
  },
  { id: "fecha", numeric: false, disablePadding: true, label: "FECHA", sorteable: true, width: "90px", show: true, fixed: true },
  { id: "nit", numeric: false, disablePadding: true, label: "NIT", sorteable: false, width: "100px", show: true, fixed: true },
  {
    id: "razon_social",
    numeric: false,
    disablePadding: true,
    label: "RAZON SOCIAL",
    sorteable: false,
    width: "200px",
    show: true,
    fixed: true
  },
  {
    id: "autorizacion",
    numeric: true,
    disablePadding: true,
    label: "N AUTORIZACIÓN",
    sorteable: false,
    width: "200px",
    show: true,
    fixed: true
  },
  { id: "factura", numeric: true, disablePadding: true, label: "N FACTURA", sorteable: false, width: "100px", show: true, fixed: true },
  { id: "columnaDui", numeric: false, disablePadding: true, label: "N DUI", sorteable: false, width: "100px", show: true, fixed: true },
  {
    id: "importe",
    numeric: false,
    disablePadding: true,
    label: "IMPORTE TOTAL",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: true
  },
  { id: "ice", numeric: false, disablePadding: true, label: "ICE", sorteable: false, width: "100px", show: true, fixed: false },
  { id: "iehd", numeric: false, disablePadding: true, label: "IEHD", sorteable: false, width: "100px", show: true, fixed: false },
  { id: "ipj", numeric: false, disablePadding: true, label: "IPJ", sorteable: false, width: "100px", show: true, fixed: false },
  { id: "tasas", numeric: false, disablePadding: true, label: "TASAS", sorteable: false, width: "100px", show: true, fixed: false },
  {
    id: "otros_no_cfiva",
    numeric: false,
    disablePadding: true,
    label: "NO SUJETO CFIVA",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: false
  },
  { id: "exentos", numeric: false, disablePadding: true, label: "EXENTOS", sorteable: false, width: "100px", show: true, fixed: false },
  { id: "tasa_cero", numeric: false, disablePadding: true, label: "TASA CERO", sorteable: false, width: "100px", show: true, fixed: false },
  { id: "subtotal", numeric: false, disablePadding: true, label: "SUBTOTAL", sorteable: false, width: "100px", show: true, fixed: false },
  {
    id: "descuentos",
    numeric: false,
    disablePadding: true,
    label: "DESCUENTOS",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: false
  },
  { id: "gift_card", numeric: false, disablePadding: true, label: "GIFT CARD", sorteable: false, width: "100px", show: true, fixed: false },
  {
    id: "base_cfiva",
    numeric: false,
    disablePadding: true,
    label: "BASE CFIVA",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: true
  },
  {
    id: "cfiva", numeric: false, disablePadding: true, label: "CFIVA", sorteable: false, width: "100px", show: true,
    fixed: true
  },
  {
    id: "tipo_compra",
    numeric: false,
    disablePadding: true,
    label: "TIPO COMPRA",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: true
  },
  {
    id: "codigo_control",
    numeric: false,
    disablePadding: true,
    label: "CODIGO CONTROL",
    sorteable: false,
    width: "200px",
    show: true,
    fixed: true
  },
  {
    id: "rubro",
    numeric: false,
    disablePadding: true,
    label: "RUBRO",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: true
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "ACCIONES",
    sorteable: false,
    width: "100px",
    show: true,
    fixed: true
  },
];


function HeaderCompras(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const [heads] = useState([...headCells]);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heads.map((headCell) => {
          if (headCell.fixed || headCell.show) {
            return (
              headCell.sorteable ?
                <TableCell
                  key={headCell.id}
                  align="center"
                  // width={headCell.width}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell> : <TableCell
                  key={headCell.id}
                  // width={headCell.width}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                >{headCell.label}</TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}

HeaderCompras.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default HeaderCompras;