import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";

const EmptyRecords = ({ loading = false }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={classes.contenido}>
          <ErrorIcon style={{ fontSize: 60, color: "#ff9800" }} />
          <Typography color="textPrimary" align="center" variant="h5">
            No se encontraron Registros
          </Typography>
          <Typography color="textSecondary" align="center" variant="subtitle1">
            Antes de empezar a registrar nuevas facturas por favor verifique la{" "}
            <b>SUCURSAL</b> y el <b>PERIODO</b>
          </Typography>
        </div>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contenido: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default EmptyRecords;
