/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect
} from "react";

export const useModal = (funcOnClose = () => {}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!open) {
      funcOnClose();
    }
  }, [open]);
  return {
    open,
    setOpen
  };
};