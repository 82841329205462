import Modal from "./FormModal";
import { Button } from "@material-ui/core";

const Confirm = ({
  open = false,
  title = "",
  body = "",
  onClose = () => {},
  onConfirmation = () => {},
}) => {
  return (
    <Modal open={open} handleClose={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Actions>
        <Button color="primary" onClick={onConfirmation}>
          CONFIRMAR
        </Button>
        <Button color="primary" onClick={onClose}>
          CANCELAR
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default Confirm;
