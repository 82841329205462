import { Container } from '@material-ui/core';
import './styles.css';

const MainApp = ({ children }) => {
  return (
    <Container className="main-app">
      {children}
    </Container>
  );
}

export default MainApp;