import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import HeaderCompras from "../HeaderCompras";
import moment from "moment";
import Reminder from "../../../components/atoms/reminder";
import { UPDATE, DELETE } from "../../../store/types";
import { currencyFormat } from "../../../utils/currency";
import { curtText } from "../../../utils/text";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function TableCompras({ prepareAction = () => { } }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("correlativo");
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <HeaderCompras
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={empresa.compras?.length}
            />
            <TableBody>
              {stableSort(empresa.compras, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index + 1}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <Reminder date={row.updated_at} interval={10} />
                      <TableCell align="right">
                        {moment(row.fecha, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="right">{row.nit}</TableCell>
                      <TableCell align="left">{curtText(row.razon_social, 20)}</TableCell>
                      <TableCell align="right">{curtText(row.autorizacion, 18)}</TableCell>
                      <TableCell align="right">{row.factura}</TableCell>
                      <TableCell align="right">{row.dui}</TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.importe)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.ice)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.iehd)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.ipj)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.tasas)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.otros_no_cfiva)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.exentos)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.tasa_cero)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.subtotal)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.descuentos)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.gift_card)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.base_cfiva)}
                      </TableCell>
                      <TableCell align="right">
                        {currencyFormat(row.cfiva)}
                      </TableCell>
                      <TableCell align="right">{row.tipo_compra}</TableCell>
                      <TableCell align="right">{row.codigo_control}</TableCell>
                      <TableCell align="right">{row.rubro}</TableCell>
                      <TableCell align="right">
                        {row.autorizacion.length < 20 && (
                          <IconButton
                            size="small"
                            onClick={() => prepareAction(UPDATE, { ...row })}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton
                          size="small"
                          onClick={() => prepareAction(DELETE, { ...row })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  paper: {
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    width: "2600px",
  },
  tableContainer: {
    height: "calc(100%)",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
