/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setEmpresaAction } from "../../store/actions/empresaActions";
import { useRequest } from "../../hooks/useRequest";
import { ADMIN } from "../../store/types/empresaTypes";
import logo from "../../assets/images/logo.jpeg";

export default function SignInSide() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [empresa, setEmpresa] = useState({});
  const [redirect, setRedirect] = useState(false);

  const {
    resp: empresas,
    loading,
    request: requestEmpresas,
  } = useRequest("/empresas", "get");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (!token || user !== ADMIN) {
      history.push("/");
    } else {
      requestEmpresas("", {}, token);
    }
  }, []);

  useEffect(() => {
    if (redirect) {
      history.push("/empresa");
    }
  }, [redirect]);

  const { request: requestEmpresa } = useRequest(
    `/empresas/${empresa?.id_empresa}`,
    "get",
    (resp) => {
      dispatch(setEmpresaAction(resp));
      setRedirect(true);
    }
  );

  const handleSubmit = (e) => {
    const token = localStorage.getItem("token");
    requestEmpresa("", {}, token);
    e.preventDefault();
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt="logo_esetel" />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Autocomplete
              options={empresas || []}
              autoHighlight
              loading={loading}
              noOptionsText="No existen empresas registradas"
              getOptionLabel={(empresa) =>
                `${empresa.razon_social} - ${empresa.nit}`
              }
              getOptionSelected={(e, empresa) => empresa.id_empresa}
              onChange={(e, value) => setEmpresa(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=" RAZON SOCIAL - NIT"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                Array.isArray(empresas) &&
                !empresas?.some((e) => e.id_empresa === empresa?.id_empresa)
              }
            >
              Ingresar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6}>
                <NavLink to="/">Salir</NavLink>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                NuevaEmpresa?<NavLink to="/registro">{" Registrar"}</NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
