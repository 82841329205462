import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  {
    id: "correlativo",
    numeric: true,
    disablePadding: true,
    label: "N",
    sorteable: false,
    witdh: "100px",
  },
  {
    id: "codigo",
    numeric: true,
    disablePadding: true,
    label: "CODIGO",
    sorteable: false,
    witdh: "100px",
  },
  {
    id: "zona",
    numeric: false,
    disablePadding: true,
    label: "ZONA/LUGAR",
    sorteable: false,
    witdh: "100px",
  },
  {
    id: "direccion",
    numeric: false,
    disablePadding: true,
    label: "DIRECCION",
    sorteable: false,
    witdh: "100px",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: true,
    label: "ACCIONES",
    sorteable: false,
    witdh: "100px",
  },
];

function HeaderSucursales(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.sorteable ? (
            <TableCell
              key={headCell.id}
              align="center"
              width={headCell.width}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              width={headCell.width}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

HeaderSucursales.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default HeaderSucursales;
