import {
    INCREMENTS_COUNTER,
    DECREMENTS_COUNTER,
    RESET_COUNTER,
    FETCH_TEXT,
    GET_TODOS
  } from '../types';
  
  const initialState = {
    count: 1,
    text: '',
    todos: ''
  }
  
  export const counterReducer = (state = initialState, action) => {
    switch (action.type) {
      case INCREMENTS_COUNTER:
        return { ...state, count: state.count + 1 };
      case DECREMENTS_COUNTER:
        return { ...state, count: state.count - 1 };
      case RESET_COUNTER:
        return { ...state, count: 1 };
    case FETCH_TEXT:
        return { ...state, text: action.payload};
    case GET_TODOS:
        return { ...state, todos: [...action.todos]};
      default:
        return state
    }
  }