/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MainListItems from "./ListItems";
import { useSelector, useDispatch } from "react-redux";
import { setSucursalAction } from "../../store/actions/empresaActions";
import { useEffect, useState } from "react";
import Empresa from "./Empresa";
import { ADMIN } from "../../store/types/empresaTypes";

export default function Dashboard({ children }) {
  const history = useHistory();
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openEmpresa, setOpenEmpresa] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleRedirectInicio = () => {
    dispatch(setSucursalAction(""));
    const user = localStorage.getItem("user");
    if (user === ADMIN) {
      history.push("/admin");
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    dispatch(setSucursalAction(empresa?.sucursales[0]?.id_sucursal || ""));
  }, [empresa.sucursales]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (!token || !user || !empresa?.id_empresa) {
      history.push("/");
    }
  }, [empresa]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title} onClick={() => setOpenEmpresa(true)}>
            <Typography variant="h6" color="inherit" noWrap>
              {empresa.razon_social}
            </Typography>
            <Typography variant="body2" color="inherit" noWrap>
              {empresa.nit}
            </Typography>
          </div>
          <FormControl className={classes.formControl}>
            {empresa.sucursales.length > 0 ? (
              <>
                <InputLabel
                  className={classes.labelSucursal}
                  id="sucursal-form"
                >
                  Sucursal
                </InputLabel>
                <Select
                  className="sucursal-div"
                  labelId="sucursal-form"
                  id="sucursal-select"
                  value={empresa?.sucursal || ""}
                  onChange={(e) => dispatch(setSucursalAction(e.target.value))}
                >
                  {empresa.sucursales.map((sucursal, index) => (
                    <MenuItem key={index} value={sucursal.id_sucursal}>
                      {sucursal.zona}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}
          </FormControl>
          <IconButton color="inherit" onClick={handleRedirectInicio}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
      <Empresa open={openEmpresa} onClose={() => setOpenEmpresa(false)} />
    </div>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    cursor: "pointer",
    "&:hover *": {
      color: "black",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: "#fff",
  },
  labelSucursal: {
    color: "#fff",
  },
}));
