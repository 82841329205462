/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainApp from "../../components/templates/layout/MainApp";
import OptionsApp from "../../components/templates/layout/OptionsApp";
import BodyApp from "../../components/templates/layout/BodyApp";
import EmptyRecords from "../../components/atoms/EmptyRecords";
import {
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert as MoreVertIcon, Add as AddIcon } from "@material-ui/icons";
import AppsIcon from "@material-ui/icons/Apps";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import PublishIcon from "@material-ui/icons/Publish";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import ExposureZeroIcon from "@material-ui/icons/ExposureZero";
import "./styles.css";
import TableCombras from "./TableCompras";
import { useSelector, useDispatch } from "react-redux";
import {
  setComprasAction,
  setTotalesComprasAction,
} from "../../store/actions/empresaActions";
import {
  setMonthAction,
  setYearAction,
} from "../../store/actions/configActions";
import moment from "moment";
import { useRequest } from "../../hooks/useRequest";
import { useCompra } from "../../hooks/useCompra";
import { CREATE, UPDATE, DELETE } from "../../store/types";
import { useModal } from "../../hooks/useModal";
import FormModal from "../../components/organisms/FormModal";
import { useSnackbar } from "notistack";
import NumberFormat from "react-number-format";
import CodigoControl from "../../components/atoms/CodigoControl";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";
import SignsIcon from "@material-ui/icons/Exposure";
import DownloadIcon from "@material-ui/icons/GetApp";
import { reportPDFCompras } from "../../reports/comprasPDF";
import { reportExcelCompras } from "../../reports/comprasExcel";
import { reportExcelComprasRubro } from "../../reports/comprasExcelRubro";
import PdfModal from "../../components/organisms/PdfModal";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import { TotalesCompra } from "../../components/organisms/Totales";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useRefs } from "../../hooks/useRefs";
import {
  BOLETO_AVION,
  COMPUTARIZADA,
  GASOLINA,
  IMPORTACION,
  IMPORTACION_TASA_CERO,
  INICIAL,
  SERVICIOS,
  SIMPLE,
  TASA_CERO,
} from "../../store/types/comprasTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import CSVReader from "../../components/organisms/CSVReader";
import { useHistory } from "react-router-dom";

const Compras = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [operation, setOperation] = useState(CREATE);
  const { open, setOpen } = useModal(() => {});
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const { month, year } = useSelector(({ configReducer }) => configReducer);
  const { compra, setCompra, resetCompra, tipos } = useCompra(
    `${year}-${month}-01`,
    empresa
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElShorcut, setAnchorElShorcut] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [totalesOpen, setTotalesOpen] = useState(false);
  const { addRef, nextFocus, prevFocus, setIndex, startFocus } = useRefs(19);
  const { open: openCSV, setOpen: setOpenCSV } = useModal(() => {});

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (!token || !user || !empresa?.id_empresa) {
      history.push("/");
    }
  }, [empresa]);

  const { request: requestCompras, loading } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/compras`,
    "get",
    (resp) => {
      dispatch(setComprasAction(resp?.compras));
      dispatch(setTotalesComprasAction(resp?.totales));
    }
  );

  const { request: requestPost } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/compras`,
    "post",
    () => {
      enqueueSnackbar("Compra registrada exitosamente", {
        variant: "success",
      });
      const token = localStorage.getItem("token");
      requestCompras(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      startFocus();
      resetCompra();
    },
    () =>
      enqueueSnackbar("Factura de compra incompleta o duplicada", {
        variant: "error",
      })
  );

  const { request: requestPut } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/compras/${compra?.id_compra}`,
    "put",
    () => {
      enqueueSnackbar("Compra actualizada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestCompras(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      resetCompra();
    },
    () => enqueueSnackbar("Codigo de sucursal duplicado", { variant: "error" })
  );

  const { request: requestDelete } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/compras/${compra.id_compra}`,
    "delete",
    () => {
      enqueueSnackbar("Compra eliminada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestCompras(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      resetCompra();
    }
  );

  const { request: requestCompra, loading: loadingCompra } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/compras/-1`,
    "get",
    ({ autorizacion = "", razon_social = "", tipo = INICIAL, rubro = "" }) => {
      let aut = autorizacion;
      if (
        [IMPORTACION, IMPORTACION_TASA_CERO, BOLETO_AVION].includes(tipos.tipo)
      ) {
        aut = compra.autorizacion;
      }
      setCompra({ ...compra, autorizacion: aut, razon_social, rubro });
      tipos.changeTipo(tipo);
    }
  );

  const handleSubmit = (funcOnFubmit) => {
    setIndex(18);
    if (true) {
      funcOnFubmit();
    }
  };

  const onSubmit = (data) => {
    const token = localStorage.getItem("token");
    if (operation === CREATE) {
      requestPost("data", { ...compra, id_empresa: empresa.id_empresa }, token);
    } else if (operation === UPDATE) {
      requestPut("data", { ...compra, id_empresa: empresa.id_empresa }, token);
    } else if (operation === DELETE) {
      requestDelete("", {}, token);
    }
  };

  const onClose = (e, r) => {
    if (!r) {
      setOpen(false);
      resetCompra();
      tipos.changeTipo(INICIAL);
    }
  };

  const prepareAction = (type, compra) => {
    setOperation(type);
    setOpen(true);
    setCompra({ ...compra, day: moment(compra.fecha, "YYYY-MM-DD").date() });
  };

  const handleEnter = (key, event) => {
    if (key === "enter") {
      nextFocus();
    } else if (key === "shift+enter") {
      prevFocus();
    }
    event.preventDefault();
  };

  const handleOnBlurCurrencys = (event) => {
    const { name, value } = event.target;
    if (name === "codigo_control" && !value) {
      setCompra({ ...compra, codigo_control: "0" });
    } else if (!value) {
      setCompra({ ...compra, [name]: "0.00" });
    }
    event.preventDefault();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    requestCompras(
      "params",
      {
        inicio: `${year}-${month}-01`,
        fin: `${year}-${month}-${moment(
          `${year}-${month}`,
          "YYYY-MM"
        ).daysInMonth()}`,
      },
      token
    );
  }, [year, month, empresa.sucursal]);

  useEffect(() => {
    setCompra({
      ...compra,
      fecha: `${year}-${month}-${compra.day}`,
    });
  }, [month, year]);

  return (
    <>
      <MainApp>
        <OptionsApp>
          <div className={classes.header}>
            <Typography variant="h6" align="center">
              Compras -
              <select
                className="month-select"
                name="month"
                id="month"
                value={month}
                onChange={(e) => dispatch(setMonthAction(e.target.value))}
              >
                <option value="01">Enero</option>
                <option value="02">Febrero</option>
                <option value="03">Marzo</option>
                <option value="04">Abril</option>
                <option value="05">Mayo</option>
                <option value="06">Junio</option>
                <option value="07">Julio</option>
                <option value="08">Agosto</option>
                <option value="09">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>{" "}
              -
              <input
                className="year-input"
                type="number"
                value={year}
                onChange={(e) => dispatch(setYearAction(e.target.value))}
              />
            </Typography>
            {empresa?.sucursal !== "-1" && (
              <>
                <IconButton
                  className={classes.addBtn}
                  onClick={() => {
                    setOpen(true);
                    setOperation(CREATE);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </>
            )}
            <IconButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              className={classes.optionsBtn}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {empresa?.compras.length > 0 && (
                <MenuItem
                  onClick={() => {
                    setTotalesOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <SignsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Totales" />
                </MenuItem>
              )}

              {empresa?.compras.length > 0 && (
                <MenuItem
                  onClick={() => {
                    reportExcelCompras(
                      empresa?.compras,
                      empresa.nit,
                      `${month}${year}`
                    );
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Descargar Excel" />
                </MenuItem>
              )}
              {empresa?.compras.length > 0 && (
                <MenuItem
                  onClick={() => {
                    reportExcelComprasRubro(
                      empresa?.compras,
                      empresa.nit,
                      `${month}${year}`
                    );
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Descargar Excel Rubro" />
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setPdf(reportPDFCompras(empresa, { month, year }));
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <PrintIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Imprimir" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenCSV(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <PublishIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Cargar CSV" />
              </MenuItem>
            </Menu>
          </div>
        </OptionsApp>
        <BodyApp>
          {empresa.compras.length > 0 && !loading ? (
            <TableCombras prepareAction={prepareAction} />
          ) : (
            <EmptyRecords loading={loading} />
          )}
        </BodyApp>
      </MainApp>
      <FormModal open={open} handleClose={onClose} width="md">
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          className={classes.shorcutForm}
          onClick={(e) => setAnchorElShorcut(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorElShorcut}
          keepMounted
          open={Boolean(anchorElShorcut)}
          onClose={() => setAnchorElShorcut(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography gutterBottom variant="body2" align="center">
            <b>TIPOS</b>
          </Typography>
          <MenuItem
            selected={tipos.tipo === INICIAL}
            onClick={() => {
              tipos.changeTipo(INICIAL);
              setAnchorElShorcut(null);
            }}
          >
            <ListItemIcon>
              <DynamicFeedIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Completo" />
          </MenuItem>
          <MenuItem
            selected={tipos.tipo === GASOLINA}
            onClick={() => {
              tipos.changeTipo(GASOLINA);
              setAnchorElShorcut(null);
            }}
          >
            <ListItemIcon>
              <LocalGasStationIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Gasolina" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(IMPORTACION);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === IMPORTACION}
          >
            <ListItemIcon>
              <DirectionsBoatIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Importaciones" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(IMPORTACION_TASA_CERO);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === IMPORTACION_TASA_CERO}
          >
            <ListItemIcon>
              <AppsIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Importacion Tasa Cero" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(SIMPLE);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === SIMPLE}
          >
            <ListItemIcon>
              <FlashOnIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Simple" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(COMPUTARIZADA);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === COMPUTARIZADA}
          >
            <ListItemIcon>
              <ClosedCaptionIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Computarizada" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(SERVICIOS);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === SERVICIOS}
          >
            <ListItemIcon>
              <WbIncandescentIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Servicios" />
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              tipos.changeTipo(BOLETO_AVION);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === BOLETO_AVION}
          >
            <ListItemIcon>
              <FlightIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Boleto de Avion" />
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              tipos.changeTipo(TASA_CERO);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === TASA_CERO}
          >
            <ListItemIcon>
              <ExposureZeroIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Tasa Cero" />
          </MenuItem>
        </Menu>
        <FormModal.Title>FORMULARIO COMPRAS</FormModal.Title>
        <FormModal.Body dividers>
          <KeyboardEventHandler
            handleKeys={["enter", "shift+enter", "ctrl+enter"]}
            onKeyEvent={handleEnter}
          >
            <form
              className={classes.form}
              noValidate
              onSubmit={() => handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="tipo_compra_label">
                      TIPO DE COMPRA
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="tipo_compra_label"
                      id="tipo_compra"
                      value={compra.tipo_compra}
                      onChange={(e) =>
                        setCompra({ ...compra, tipo_compra: e.target.value })
                      }
                      label="TIPO DE COMPRA"
                    >
                      <MenuItem value={"1"}>
                        Compras para mercado interno con destino a actividades
                        gravadas.
                      </MenuItem>
                      <MenuItem value={"2"}>
                        Compras para mercado interno con destino a actividades
                        no gravadas.
                      </MenuItem>
                      <MenuItem value={"3"}>
                        Compras sujetas a proporcionalidad.
                      </MenuItem>
                      <MenuItem value={"4"}>
                        Compras para exportaciones.
                      </MenuItem>
                      <MenuItem value={"5"}>
                        Compras tanto para el mercado interno como para
                        exportaciones.
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.day}
                    onValueChange={(values, sourceInfo) => {
                      setCompra({
                        ...compra,
                        fecha: moment(
                          values.formattedValue,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD"),
                        day: values.value,
                      });
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="FECHA"
                    autoFocus
                    inputProps={{ style: { textAlign: "left" } }}
                    displayType="input"
                    type="text"
                    inputRef={(ref) => addRef(ref, 0)}
                    onFocus={() => setIndex(0)}
                    suffix={`/${month}/${year}`}
                    allowNegative={false}
                    decimalScale={0}
                    isNumericString={true}
                    allowLeadingZeros={true}
                    allowEmptyFormatting={false}
                  />
                </Grid>
                <Grid item xs={3} className={classes.containerNit}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.nit}
                    onValueChange={(values, sourceInfo) => {
                      setCompra({ ...compra, nit: values.value });
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="NIT"
                    inputProps={{ style: { textAlign: "left" } }}
                    displayType="input"
                    type="text"
                    allowNegative={false}
                    decimalScale={0}
                    inputRef={(ref) => addRef(ref, 1)}
                    onFocus={(e) => {
                      setIndex(1);
                      e.target.select();
                    }}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    onBlur={() => {
                      const token = localStorage.getItem("token");
                      requestCompra("params", { nit: compra.nit }, token);
                    }}
                  />
                  {loadingCompra && (
                    <CircularProgress className={classes.searchNit} size={30} />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    value={compra.razon_social}
                    onChange={(e) =>
                      setCompra({
                        ...compra,
                        razon_social: e.target.value?.toUpperCase(),
                      })
                    }
                    inputRef={(ref) => addRef(ref, 2)}
                    onFocus={(e) => {
                      setIndex(2);
                      e.target.select();
                    }}
                    label="RAZON SOCIAL"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-rubro"
                    fullWidth
                    value={compra?.rubro}
                    onChange={(e) =>
                      setCompra({
                        ...compra,
                        rubro: e.target.value?.toUpperCase(),
                      })
                    }
                    inputRef={(ref) => addRef(ref, 3)}
                    onFocus={(e) => {
                      setIndex(3);
                      e.target.select();
                    }}
                    label="RUBRO"
                    variant="outlined"
                  />
                </Grid>
                {tipos.fields.factura.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.factura}
                      onValueChange={(values, sourceInfo) => {
                        setCompra({ ...compra, factura: values.value });
                      }}
                      customInput={TextField}
                      variant="outlined"
                      label={
                        tipos.tipo === BOLETO_AVION
                          ? "BOLETO DE AVION"
                          : "NUMERO DE FACTURA"
                      }
                      inputProps={{
                        style: { textAlign: "center" },
                        readOnly: tipos.fields.factura.readOnly,
                      }}
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 4)}
                      onFocus={(e) => {
                        setIndex(4);
                        e.target.select();
                      }}
                      type="text"
                      allowNegative={false}
                      decimalScale={0}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                    />
                  </Grid>
                )}
                {tipos.fields.dui.show && (
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      value={compra.dui}
                      onChange={(e) =>
                        setCompra({
                          ...compra,
                          dui: e.target.value,
                        })
                      }
                      label="N DUI"
                      inputRef={(ref) => addRef(ref, 5)}
                      onFocus={(e) => {
                        setIndex(5);
                        e.target.select();
                      }}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    value={compra.autorizacion}
                    onChange={(e) =>
                      setCompra({
                        ...compra,
                        autorizacion: e.target.value,
                      })
                    }
                    inputRef={(ref) => addRef(ref, 6)}
                    onFocus={(e) => {
                      setIndex(6);
                      e.target.select();
                    }}
                    inputProps={{
                      readOnly: tipos.fields.autorizacion.readOnly,
                    }}
                    label="N AUTORIZACION"
                    variant="outlined"
                  />
                </Grid>
                {tipos.fields.codigo_control.show && (
                  <Grid item xs={3}>
                    <TextField
                      label="CODIGO DE CONTROL"
                      value={compra.codigo_control}
                      onChange={(e) => {
                        setCompra({
                          ...compra,
                          codigo_control: e.target.value,
                        });
                      }}
                      InputProps={{
                        inputComponent: CodigoControl,
                        inputRef: (ref) => addRef(ref, 7),
                        readOnly: tipos.fields.codigo_control.readOnly,
                      }}
                      InputLabelProps={{
                        shrink: compra?.codigo_control.length !== 0,
                      }}
                      name="codigo_control"
                      onFocus={(e) => {
                        setIndex(7);
                        e.target.select();
                      }}
                      onBlur={handleOnBlurCurrencys}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.importe}
                    onValueChange={({ value: importe }, sourceInfo) => {
                      setCompra({ ...compra, importe });
                    }}
                    customInput={TextField}
                    inputProps={{ style: { textAlign: "right" } }}
                    variant="outlined"
                    label="IMPORTE TOTAL"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    inputRef={(ref) => addRef(ref, 8)}
                    onFocus={(e) => {
                      setIndex(8);
                      e.target.select();
                    }}
                    name="importe"
                    onBlur={handleOnBlurCurrencys}
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                {tipos.fields.ice.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.ice}
                      onValueChange={({ value: ice }, sourceInfo) => {
                        setCompra({ ...compra, ice });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.ice.readOnly,
                      }}
                      variant="outlined"
                      label="ICE"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 9)}
                      onFocus={(e) => {
                        setIndex(9);
                        e.target.select();
                      }}
                      name="ice"
                      onBlur={handleOnBlurCurrencys}
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.iehd.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.iehd}
                      onValueChange={({ value: iehd }, sourceInfo) => {
                        setCompra({ ...compra, iehd });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.iehd.readOnly,
                      }}
                      variant="outlined"
                      label="IEHD"
                      inputRef={(ref) => addRef(ref, 10)}
                      onFocus={(e) => {
                        setIndex(10);
                        e.target.select();
                      }}
                      name="iehd"
                      onBlur={handleOnBlurCurrencys}
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.ipj.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.ipj}
                      onValueChange={({ value: ipj }, sourceInfo) => {
                        setCompra({ ...compra, ipj });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.ipj.readOnly,
                      }}
                      variant="outlined"
                      label="IPJ"
                      fullWidth
                      decimalSeparator="."
                      inputRef={(ref) => addRef(ref, 11)}
                      onFocus={(e) => {
                        setIndex(11);
                        e.target.select();
                      }}
                      name="ipj"
                      onBlur={handleOnBlurCurrencys}
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.tasas.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.tasas}
                      onValueChange={({ value: tasas }, sourceInfo) => {
                        setCompra({ ...compra, tasas });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.tasas.readOnly,
                      }}
                      variant="outlined"
                      label="TASAS"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 12)}
                      onFocus={(e) => {
                        setIndex(12);
                        e.target.select();
                      }}
                      name="tasas"
                      onBlur={handleOnBlurCurrencys}
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.otros_no_cfiva.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.otros_no_cfiva}
                      onValueChange={(
                        { value: otros_no_cfiva },
                        sourceInfo
                      ) => {
                        setCompra({ ...compra, otros_no_cfiva });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.otros_no_cfiva.readOnly,
                      }}
                      variant="outlined"
                      label="NO SUJETO A CFIVA"
                      inputRef={(ref) => addRef(ref, 13)}
                      onFocus={(e) => {
                        setIndex(13);
                        e.target.select();
                      }}
                      name="otros_no_cfiva"
                      onBlur={handleOnBlurCurrencys}
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.exentos.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.exentos}
                      onValueChange={({ value: exentos }, sourceInfo) => {
                        setCompra({ ...compra, exentos });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.exentos.readOnly,
                      }}
                      variant="outlined"
                      label="EXENTOS"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 14)}
                      onFocus={(e) => {
                        setIndex(14);
                        e.target.select();
                      }}
                      name="exentos"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.tasa_cero.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.tasa_cero}
                      onValueChange={({ value: tasa_cero }, sourceInfo) => {
                        setCompra({ ...compra, tasa_cero });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.tasa_cero.readOnly,
                      }}
                      variant="outlined"
                      label="TASA CERO"
                      fullWidth
                      inputRef={(ref) => addRef(ref, 15)}
                      onFocus={(e) => {
                        setIndex(15);
                        e.target.select();
                      }}
                      name="tasa_cero"
                      onBlur={handleOnBlurCurrencys}
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.subtotal}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="SUBTOTAL"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                {tipos.fields.descuentos.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.descuentos}
                      onValueChange={({ value: descuentos }, sourceInfo) => {
                        setCompra({ ...compra, descuentos });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.descuentos.readOnly,
                      }}
                      variant="outlined"
                      label="DESCUENTOS"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 16)}
                      onFocus={() => setIndex(16)}
                      name="descuentos"
                      onBlur={handleOnBlurCurrencys}
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.gift_card.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={compra.gift_card}
                      onValueChange={({ value: gift_card }, sourceInfo) => {
                        setCompra({ ...compra, gift_card });
                      }}
                      customInput={TextField}
                      inputProps={{
                        style: { textAlign: "right" },
                        readOnly: tipos.fields.gift_card.readOnly,
                      }}
                      variant="outlined"
                      label="GIFT CARD"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      inputRef={(ref) => addRef(ref, 17)}
                      onFocus={() => setIndex(17)}
                      name="gift_card"
                      onBlur={handleOnBlurCurrencys}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.base_cfiva}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="BASE CFIVA"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={compra.cfiva}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="CFIVA"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
              </Grid>
            </form>
          </KeyboardEventHandler>
        </FormModal.Body>
        <FormModal.Actions>
          <Button
            onClick={() => handleSubmit(onSubmit)}
            color="primary"
            type="submit"
            ref={(ref) => addRef(ref, 18)}
          >
            {operation}
          </Button>
          <Button onClick={onClose} color="primary">
            CANCELAR
          </Button>
        </FormModal.Actions>
      </FormModal>
      <PdfModal
        pdf={pdf}
        open={Boolean(pdf)}
        handleClose={() => setPdf(null)}
      />
      <FormModal
        open={totalesOpen}
        handleClose={(e, r) => {
          if (!r) setTotalesOpen(false);
        }}
        width="sm"
      >
        <FormModal.Title>TOTALES</FormModal.Title>
        <FormModal.Body dividers>
          <TotalesCompra totales={empresa?.totalesCompras} />
        </FormModal.Body>
        <FormModal.Actions>
          <Button onClick={() => setTotalesOpen(false)} color="primary">
            CERRAR
          </Button>
        </FormModal.Actions>
      </FormModal>
      <CSVReader
        tipo="compras"
        open={openCSV}
        handleClose={() => setOpenCSV(false)}
        onSuccess={() => {
          const token = localStorage.getItem("token");
          requestCompras(
            "params",
            {
              inicio: `${year}-${month}-01`,
              fin: `${year}-${month}-${moment(
                `${year}-${month}`,
                "YYYY-MM"
              ).daysInMonth()}`,
            },
            token
          );
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    width: "100%",
  },
  addBtn: {
    position: "absolute",
    right: 0,
    top: -5,
  },
  optionsBtn: {
    position: "absolute",
    left: 0,
    top: -5,
  },
  shorcutForm: {
    position: "absolute",
    right: 0,
    top: 5,
  },
  inputReadOnly: {
    backgroundColor: "#e3f2fd",
  },
  containerNit: {
    position: "relative",
  },
  searchNit: {
    position: "absolute",
    top: 20,
    right: 15,
  },
}));

export default Compras;
