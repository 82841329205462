import { combineReducers } from 'redux';
import { counterReducer } from './counterReducer';
import { empresaReducer } from './empresaReducer';
import { configReducer } from './configReducer';

const rootReducer = combineReducers({
    counterReducer,
    empresaReducer,
    configReducer,
});

export default rootReducer;