import XLSX from "xlsx";
import moment from "moment";
import {
  currencyFormat
} from '../utils/currency';


export const reportExcelVentas = (ventas, nit, periodo) => {
  // const workSheet = XLSX.utils.aoa_to_sheet(formatExcelVentas(ventas));
  const workSheet = XLSX.utils.json_to_sheet(formatExcelVentas(ventas));
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "ventas");
  XLSX.write(workBook, {
    bookType: "xlsx",
    type: "binary"
  });
  XLSX.writeFile(workBook, `Ventas_${nit}_${periodo}.xlsx`)
};

const formatExcelVentas = (ventas = []) => {
  const resp = [];
  ventas.forEach((venta, index) => {
    resp.push({
      "Nº": index + 1,
      "ESPECIFICACION": 2,
      "FECHA DE LA FACTURA": moment(venta.fecha, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "N° DE LA FACTURA": venta.factura,
      "CODIGO DE AUTORIZACION": venta.autorizacion,
      "NIT / CI CLIENTE": venta.nit,
      "COMPLEMENTO": venta.complemento,
      "NOMBRE O RAZON SOCIAL": venta.razon_social,
      "IMPORTE TOTAL DE LA VENTA": currencyFormat(venta.importe),
      "IMPORTE ICE": currencyFormat(venta.ice),
      "IMPORTE IEHD": currencyFormat(venta.iehd),
      "IMPORTE IPJ": currencyFormat(venta.ipj),
      "TASAS": currencyFormat(venta.tasas),
      "OTROS NO SUJETOS AL IVA": currencyFormat(venta.otros_no_dfiva),
      "EXPORTACIONES Y OPERACIONES EXENTAS": currencyFormat(venta.exportaciones),
      "VENTAS GRAVADAS A TASA CERO": currencyFormat(venta.tasa_cero),
      "SUBTOTAL": currencyFormat(venta.subtotal),
      "DESCUENTOS, BONIFICACIONES Y REBAJAS SUJETAS AL IVA": currencyFormat(venta.descuentos),
      "IMPORTE GIFT CARD": currencyFormat(venta.gift_card),
      "IMPORTE BASE PARA DEBITO FISCAL": currencyFormat(venta.base_dfiva),
      "DEBITO FISCAL": currencyFormat(venta.dfiva),
      "ESTADO": venta.estado,
      "CODIGO DE CONTROL": venta.codigo_control,
      "TIPO DE VENTA": venta.tipo_venta
    });
  });
  return resp;
}