import {
  SET_MONTH,
  SET_YEAR
} from '../types/configTypes';

const initialState = {
  month: "",
  year: "",
};

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MONTH:
      return {
        ...state,
        month: action.month
      };
    case SET_YEAR:
      return {
        ...state,
        year: action.year
      };
    default:
      return state;
  }

}