import Dashboard from "./views/Dashboard";
import { Route, Switch } from "react-router";
import routes from "./routes";
import Registro from "./views/Registro";
import Home from "./views/Home";
import Login from "./views/Login";
import "moment/locale/es";
import moment from "moment";
import "./assets/fontsJs";
import "./app.css";

function App() {
  moment.locale("es");
  return (
    <div className="App">
      <Switch>
        <Route exact path="/admin">
          <Home />
        </Route>
        <Route path="/empresa">
          <Dashboard>{routes}</Dashboard>
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/registro">
          <Registro />
        </Route>
        <Route exact path="/">
          <Login />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
