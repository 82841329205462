import { Route, Switch } from "react-router";
import Inicio from '../views/Inicio';
import Compras from '../views/Compras';
import Ventas from '../views/Ventas';
import Sucursales from '../views/Sucursales';
import Home from '../views/Home';

const routes = (
  <Switch>
    <Route exact path="/empresa">
      <Inicio />
    </Route>
    <Route exact path="/empresa/compras">
      <Compras />
    </Route>
    <Route exact path="/empresa/ventas">
      <Ventas />
    </Route>
    <Route exact path="/empresa/sucursales">
      <Sucursales />
    </Route>
    <Route exact path="/home">
      <Home/>
    </Route>
  </Switch>
);

export default routes;