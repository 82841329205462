/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import BusinessIcon from "@material-ui/icons/Business";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSnackbar } from "notistack";
import { Link as LinkTo, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useRequest } from "../../hooks/useRequest";
import { ADMIN } from "../../store/types/empresaTypes";

const defaultValues = {
  responsable: "",
  razon_social: "",
  nit: "",
  ci: "",
  name: "",
  password: "",
};

export default function Registro() {
  const classes = useStyles();
  const history = useHistory();
  const [token, setToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(defaultValues);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (!token || user !== ADMIN) {
      history.push("/");
    } else {
      setToken(token);
    }
  }, []);

  const { request } = useRequest(
    "/empresas",
    "post",
    () => {
      enqueueSnackbar("Empresa registrada exitosamente", {
        variant: "success",
      });
      reset({});
    },
    () => enqueueSnackbar("Empresa existente", { variant: "error" })
  );

  const onSubmit = (data) => request("data", data, token);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          NUEVA EMPRESA
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="razon_social"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    name="razon_social"
                    variant="outlined"
                    required
                    fullWidth
                    id="razon_social"
                    label="Razon Social"
                    autoFocus
                    error={!!errors.razon_social}
                    helperText={
                      !!errors.razon_social && "Este Campo es requerido"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="responsable"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    name="responsable"
                    variant="outlined"
                    required
                    fullWidth
                    id="responsable"
                    label="Titular"
                    error={!!errors.responsable}
                    helperText={
                      !!errors.responsable && "Este Campo es requerido"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="ci"
                control={control}
                rules={{ required: true, pattern: /[0-9]{5,}\s[a-zA-Z]{2}/i }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    name="ci"
                    placeholder="1234567 LP"
                    variant="outlined"
                    required
                    fullWidth
                    id="ci"
                    label="Carnet"
                    error={!!errors.ci}
                    helperText={
                      errors.ci?.type === "required"
                        ? "Este Campo es requerido"
                        : errors.ci?.type === "pattern"
                        ? "Seguir formato Ej. 54321 CB"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="nit"
                control={control}
                rules={{ required: true, pattern: /[0-9]{6,}/i }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    name="nit"
                    variant="outlined"
                    required
                    fullWidth
                    id="nit"
                    label="NIT"
                    error={!!errors.nit}
                    helperText={
                      errors.nit?.type === "required"
                        ? "Este Campo es requerido"
                        : errors.nit?.type === "pattern"
                        ? "Solo ingresar numeros"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    id="user-empresa"
                    label="Usuario"
                    error={!!errors.name}
                    helperText={!!errors.name && "Este Campo es requerido"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    type="password"
                    name="password"
                    variant="outlined"
                    required
                    fullWidth
                    id="password-empresa"
                    label="Contraseña"
                    error={!!errors.password}
                    helperText={!!errors.password && "Este Campo es requerido"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit(onSubmit)}
          >
            REGISTRAR
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {"Ya registrarste la empresa? "}
              <Link component="span" variant="body2">
                <LinkTo to="/admin">{"Regresar"}</LinkTo>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
