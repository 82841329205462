import React from "react";
import Cleave from "cleave.js/react";

export default function CleaveInput(props) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        delimiters: ["-", "-", "-", "-"],
        blocks: [2, 2, 2, 2, 2],
        uppercase: true,
        delimiterLazyShow: true,
      }}
      {...rest}
    />
  );
};