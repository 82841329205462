import XLSX from "xlsx";
import moment from 'moment';
import { currencyFormat } from '../utils/currency';

export const reportExcelCompras = (compras, nit, periodo) => {
  // const workSheet = XLSX.utils.aoa_to_sheet(formatExcelCompras(compras));
  const workSheet = XLSX.utils.json_to_sheet(formatExcelCompras(compras));
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "compras");
  XLSX.write(workBook, {
    bookType: "xlsx",
    type: "binary"
  });
  XLSX.writeFile(workBook, `Compras_${nit}_${periodo}.xlsx`)
};

const formatExcelCompras = (compras = []) => {
  const resp = [];
  compras.forEach((compra, index) => {
    resp.push({
      "Nº": index + 1,
      "ESPECIFICACION": 1,
      "NIT PROVEEDOR": compra.nit,
      "RAZON SOCIAL PROVEEDOR": compra.razon_social,
      "CODIGO DE AUTORIZACION": compra.autorizacion,
      "NUMERO FACTURA": compra.factura,
      "NUMERO DUI/DIM": compra.dui,
      "FECHA DE FACTURA/DUI/DIM": moment(compra.fecha, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "IMPORTE TOTAL COMPRA": currencyFormat(compra.importe),
      "IMPORTE ICE": currencyFormat(compra.ice),
      "IMPORTE IEHD": currencyFormat(compra.iehd),
      "IMPORTE IPJ": currencyFormat(compra.ipj),
      "TASAS": currencyFormat(compra.tasas),
      "OTRO NO SUJETO A CREDITO FISCAL": currencyFormat(compra.otros_no_cfiva),
      "IMPORTES EXENTOS": currencyFormat(compra.exentos),
      "IMPORTE COMPRAS GRAVADAS A TASA CERO": currencyFormat(compra.tasa_cero),
      "SUBTOTAL": currencyFormat(compra.subtotal),
      "DESCUENTOS/BONIFICACIONES /REBAJAS SUJETAS AL IVA": currencyFormat(compra.descuentos),
      "IMPORTE GIFT CARD": currencyFormat(compra.gift_card),
      "IMPORTE BASE CF": currencyFormat(compra.base_cfiva),
      "CREDITO FISCAL": currencyFormat(compra.cfiva),
      "TIPO COMPRA": compra.tipo_compra,
      "CODIGO DE CONTROL": compra.codigo_control
    });
  });
  return resp;
}
