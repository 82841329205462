/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useState, useEffect } from "react";
import { useRequest } from "../../hooks/useRequest";
import {
  setMonthAction,
  setYearAction,
} from "../../store/actions/configActions";
import { setEmpresaAction } from "../../store/actions/empresaActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CLIENT } from "../../store/types/empresaTypes";
import { useSnackbar } from "notistack";
import logo from "../../assets/images/logo.jpeg";

const Login = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cuenta, setCuenta] = useState({ name: "", password: "" });
  const { resp: user, request: requestLogin } = useRequest(
    "/signin",
    "post",
    () => {},
    () => {
      enqueueSnackbar("Usuario y/o contraseña incorrecta", {
        variant: "error",
      });
    }
  );
  const [redirect, setRedirect] = useState(false);

  const { resp: empresa, request: requestEmpresa } = useRequest(
    `/empresas/${user?.id_empresa}`,
    "get"
  );

  useEffect(() => {
    if (empresa) {
      dispatch(setEmpresaAction(empresa));
      setRedirect(true);
    }
  }, [empresa]);

  useEffect(() => {
    if (user) {
      localStorage.setItem("token", user?.api_token);
      localStorage.setItem("user", user?.type_user);
      if (user?.type_user === CLIENT) {
        requestEmpresa("", {}, user?.api_token);
      } else {
        history.push("/admin");
      }
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem("token", "");
    localStorage.setItem("user", "");
    const month = moment().month();
    const year = moment().year();
    const diciembre = 12;
    dispatch(setMonthAction(month ? month : diciembre));
    dispatch(setYearAction(month ? year : year - 1));
  }, []);

  useEffect(() => {
    if (redirect) {
      history.push("/empresa");
    }
  }, [redirect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    requestLogin("data", { ...cuenta });
  };

  return (
    <div className={classes.login}>
      <Paper className={classes.paperLogin}>
        <img src={logo} alt="logo_esetel" style={{ width: "100%" }} />
        <Typography variant="h5" align="center" gutterBottom>
          Aplicativo RCV
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="USUARIO"
                variant="outlined"
                autoFocus
                fullWidth
                value={cuenta.name}
                onChange={(e) => setCuenta({ ...cuenta, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="CONTRASEÑA"
                variant="outlined"
                fullWidth
                value={cuenta.password}
                onChange={(e) =>
                  setCuenta({ ...cuenta, password: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Button
            className={classes.btnSubmit}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            INGRESAR
          </Button>
        </form>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  login: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fafafa",
  },
  paperLogin: {
    padding: "20px",
    width: "300px",
  },

  btnSubmit: {
    marginTop: "8px",
  },
}));

export default Login;
