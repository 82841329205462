import {
  useRef,
  useState
} from 'react';

export const useRefs = (tam) => {
  const itemsRef = useRef(new Array(tam));
  const [index, setIndex] = useState(0);

  const addRef = (item,i) => itemsRef.current[i] = item;

  const nextFocus = () => {
    let next = 1;
    while(!itemsRef?.current[index + next] && index + next < itemsRef.current.length) {
      next++;
    }
    itemsRef?.current[index + next]?.focus();
    setIndex(index + next);
  }
  const prevFocus = () => {
    let prev = 1;
    while(!itemsRef?.current[index - prev] && index - prev >= 0 ) {
      prev++;
    }
    itemsRef?.current[index - prev]?.focus();
    setIndex(index - prev);
  }

  const startFocus = () => itemsRef?.current[0].focus();

  return {
    addRef,
    nextFocus,
    prevFocus,
    setIndex,
    startFocus
  };
}