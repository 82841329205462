import Typography from "@material-ui/core/Typography";
import { currencyFormat } from "../../utils/currency";

export const TotalesCompra = ({ totales = {} }) => {
  return (
    <div>
      <Typography variant="h6">
        Total Importe:&emsp;<b>{currencyFormat(totales?.importe)}</b>
      </Typography>
      {totales?.ice !== '0.00' && (
        <Typography variant="h6">
          Total ICE:&emsp;<b>{currencyFormat(totales?.ice)}</b>
        </Typography>
      )}
      {totales?.iehd !== '0.00' && (
        <Typography variant="h6">
          Total IEHD:&emsp;<b>{currencyFormat(totales?.iehd)}</b>
        </Typography>
      )}
      {totales?.ipj !== '0.00' && (
        <Typography variant="h6">
          Total IPJ:&emsp;<b>{currencyFormat(totales?.ipj)}</b>
        </Typography>
      )}
      {totales?.tasas !== '0.00' && (
        <Typography variant="h6">
          Total tasas:&emsp;<b>{currencyFormat(totales?.tasas)}</b>
        </Typography>
      )}
      {totales?.otros_no_cfiva !== '0.00' && (
        <Typography variant="h6">
          Total No sujeto a Credito Fiscal:&emsp;<b>{currencyFormat(totales?.otros_no_cfiva)}</b>
        </Typography>
      )}
      {totales?.exentos !== '0.00' && (
        <Typography variant="h6">
          Total exentos:&emsp;<b>{currencyFormat(totales?.exentos)}</b>
        </Typography>
      )}
      {totales?.tasa_cero !== '0.00' && (
        <Typography variant="h6">
          Total tasa cero:&emsp;<b>{currencyFormat(totales?.tasa_cero)}</b>
        </Typography>
      )}
      {totales?.subtotal !== '0.00' && (
        <Typography variant="h6">
          Total Subtotal:&emsp;<b>{currencyFormat(totales?.subtotal)}</b>
        </Typography>
      )}
      {totales?.descuentos !== '0.00' && (
        <Typography variant="h6">
          Total Descuentos:&emsp;<b>{currencyFormat(totales?.descuentos)}</b>
        </Typography>
      )}
      {totales?.gift_card !== '0.00' && (
        <Typography variant="h6">
          Total Gift Card:&emsp;<b>{currencyFormat(totales?.gift_card)}</b>
        </Typography>
      )}
      {totales?.base_cfiva !== '0.00' && (
        <Typography variant="h6">
          Total Base Credito Fiscal IVA:&emsp;<b>{currencyFormat(totales?.base_cfiva)}</b>
        </Typography>
      )}
      {totales?.cfiva !== '0.00' && (
        <Typography variant="h6">
          Total Credito Fiscal IVA:&emsp;<b>{currencyFormat(totales?.cfiva)}</b>
        </Typography>
      )}
      <Typography variant="h6">
        Cantidad de registros:&emsp;<b>{totales?.cantidad}</b>
      </Typography>
    </div>
  );
};

export const TotalesVenta = ({ totales = {} }) => {
  return (
    <div>
      <Typography variant="h6">
        Total Importe:&emsp;<b>{currencyFormat(totales?.importe)}</b>
      </Typography>
      {totales?.ice !== '0.00' && (
        <Typography variant="h6">
          Total ICE:&emsp;<b>{currencyFormat(totales?.ice)}</b>
        </Typography>
      )}
      {totales?.iehd !== '0.00' && (
        <Typography variant="h6">
          Total IEHD:&emsp;<b>{currencyFormat(totales?.iehd)}</b>
        </Typography>
      )}
      {totales?.ipj !== '0.00' && (
        <Typography variant="h6">
          Total IPJ:&emsp;<b>{currencyFormat(totales?.ipj)}</b>
        </Typography>
      )}
      {totales?.tasas !== '0.00' && (
        <Typography variant="h6">
          Total tasas:&emsp;<b>{currencyFormat(totales?.tasas)}</b>
        </Typography>
      )}
      {totales?.otros_no_dfiva !== '0.00' && (
        <Typography variant="h6">
          Total No sujeto a Credito Fiscal:&emsp;<b>{currencyFormat(totales?.otros_no_dfiva)}</b>
        </Typography>
      )}
      {totales?.exportaciones !== '0.00' && (
        <Typography variant="h6">
          Total Exportaciones:&emsp;<b>{currencyFormat(totales?.exportaciones)}</b>
        </Typography>
      )}
      {totales?.tasa_cero !== '0.00' && (
        <Typography variant="h6">
          Total Tasa Cero:&emsp;<b>{currencyFormat(totales?.tasa_cero)}</b>
        </Typography>
      )}
      {totales?.subtotal !== '0.00' && (
        <Typography variant="h6">
          Total Subtotal:&emsp;<b>{currencyFormat(totales?.subtotal)}</b>
        </Typography>
      )}
      {totales?.descuentos !== '0.00' && (
        <Typography variant="h6">
          Total Descuentos:&emsp;<b>{currencyFormat(totales?.descuentos)}</b>
        </Typography>
      )}
      {totales?.gift_card !== '0.00' && (
        <Typography variant="h6">
          Total Gift Card:&emsp;<b>{currencyFormat(totales?.gift_card)}</b>
        </Typography>
      )}
      {totales?.base_dfiva !== '0.00' && (
        <Typography variant="h6">
          Total Base Debito Fiscal IVA:&emsp;<b>{currencyFormat(totales?.base_dfiva)}</b>
        </Typography>
      )}
      {totales?.dfiva !== '0.00' && (
        <Typography variant="h6">
          Total Debito Fiscal IVA:&emsp;<b>{currencyFormat(totales?.dfiva)}</b>
        </Typography>
      )}
      <Typography variant="h6">
        Cantidad de registros:&emsp;<b>{totales?.cantidad}</b>
      </Typography>
    </div>
  );
};