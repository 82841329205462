/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField } from "@material-ui/core";
import Form from "../../../components/organisms/FormModal";
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useRequest } from "../../../hooks/useRequest";
import { setEmpresaAction } from "../../../store/actions/empresaActions";
import Confirm from "../../../components/organisms/Confirm";
import { useHistory } from "react-router-dom";
import { ADMIN } from "../../../store/types/empresaTypes";

const initialEmpresa = {
  id_empresa: -1,
  razon_social: "",
  responsable: "",
  ci: "",
  nit: "",
};

const useStyles = makeStyles((theme) => ({
  actions: { position: "relative" },
  deleteButton: { position: "absolute", left: 0 },
}));

const Empresa = ({
  open = true,
  onClose = () => {},
  onConfirmation = () => {},
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [enterprise, setEnterprise] = useState({ ...initialEmpresa });
  const [cuenta, setCuenta] = useState({ name: "", password: "" });
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const { resp: newEmpresa, request: reqPutEmpresa } = useRequest(
    `empresas/${empresa?.id_empresa}`,
    "put",
    () => {
      enqueueSnackbar("DATOS ACTUALIZADOS CORRECTAMENTE", {
        variant: "success",
      });
      setConfirmUpdate(false);
    }
  );

  const { request: reqDeleteEmpresa } = useRequest(
    `empresas/${empresa?.id_empresa}`,
    "delete",
    () => {
      enqueueSnackbar("EMPRESA ELIMINADA", {
        variant: "success",
      });
      history.push("/");
    }
  );

  const { resp: newUser, request: reqUpdateUser } = useRequest(
    `users/${empresa?.id_empresa}`,
    "put",
    () => {
      enqueueSnackbar("USUARIO ACTUALIZADO", {
        variant: "success",
      });
    }
  );

  useEffect(() => {
    if (newEmpresa) {
      dispatch(setEmpresaAction(newEmpresa));
    }
  }, [newEmpresa]);

  useEffect(() => {
    if (newUser) {
      setCuenta({ name: newUser?.name, password: newUser?.password });
    }
  }, [newUser]);

  useEffect(() => {
    setEnterprise({
      id_empresa: empresa?.id_empresa,
      razon_social: empresa?.razon_social,
      responsable: empresa?.responsable,
      ci: empresa?.ci,
      nit: empresa?.nit,
    });
    setCuenta({ name: empresa?.name, password: empresa?.password });
  }, [empresa]);

  const handleClose = () => {
    onClose();
  };

  const handleConfirmation = () => {
    onConfirmation();
    setConfirmUpdate(true);
  };

  return (
    <>
      <Form open={open} width="sm" fullWidth handleClose={handleClose}>
        <Form.Title>FORMULARIO DE CONTACTO</Form.Title>
        <Form.Body>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="NIT"
                variant="outlined"
                autoFocus
                value={enterprise?.nit}
                onChange={(e) =>
                  setEnterprise({ ...enterprise, nit: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                type="text"
                label="RAZON SOCIAL"
                variant="outlined"
                fullWidth
                value={enterprise?.razon_social}
                onChange={(e) =>
                  setEnterprise({ ...enterprise, razon_social: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="CI"
                variant="outlined"
                value={enterprise?.ci}
                onChange={(e) =>
                  setEnterprise({ ...enterprise, ci: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                type="text"
                label="TITULAR / REPRESENTANTE LEGAL"
                variant="outlined"
                fullWidth
                value={enterprise?.responsable}
                onChange={(e) =>
                  setEnterprise({ ...enterprise, responsable: e.target.value })
                }
              />
            </Grid>
            {localStorage.getItem("user") === ADMIN && (
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label="USUARIO"
                  variant="outlined"
                  fullWidth
                  value={cuenta?.name}
                  onChange={(e) =>
                    setCuenta({ ...cuenta, name: e.target.value })
                  }
                />
              </Grid>
            )}
            {localStorage.getItem("user") === ADMIN && (
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label="CONTRASEÑA"
                  variant="outlined"
                  fullWidth
                  value={cuenta?.password}
                  onChange={(e) =>
                    setCuenta({ ...cuenta, password: e.target.value })
                  }
                />
              </Grid>
            )}
          </Grid>
        </Form.Body>
        <Form.Actions className={classes.actions}>
          <Button onClick={handleConfirmation} color="primary">
            ACTUALIZAR
          </Button>
          <Button color="primary" onClick={handleClose}>
            CERRAR
          </Button>
        </Form.Actions>
      </Form>
      <Confirm
        open={confirmUpdate}
        title="ACTUALIZACION"
        body="LA INFORMACION DE LA EMPRESA SE ACTUALIZARA, ESTA SEGURO DE REALIZAR ESTA ACCION"
        onClose={() => setConfirmUpdate(false)}
        onConfirmation={() => {
          const token = localStorage.getItem("token");
          const user = localStorage.getItem("user");
          reqPutEmpresa("data", { ...enterprise }, token);
          if (user === ADMIN) {
            reqUpdateUser("data", { ...cuenta }, token);
          }
        }}
      />
      <Confirm
        open={confirmDelete}
        title="ELIMINACION"
        body="TOTA LA EMPRESA SE ELIMINARA INCLUIDAS SUCURSALES, COMPRASY Y VENTAS, ESTA SEGURO DE REALIZAR ESTA ACCION"
        onClose={() => setConfirmDelete(false)}
        onConfirmation={() => reqDeleteEmpresa()}
      />
    </>
  );
};

export default Empresa;
