/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainApp from "../../components/templates/layout/MainApp";
import OptionsApp from "../../components/templates/layout/OptionsApp";
import BodyApp from "../../components/templates/layout/BodyApp";
import EmptyRecords from "../../components/atoms/EmptyRecords";
import {
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert as MoreVertIcon, Add as AddIcon } from "@material-ui/icons";
import "./styles.css";
import TableVentas from "./TableVentas";
import { useSelector, useDispatch } from "react-redux";
import {
  setTotalesVentasAction,
  setVentasAction,
} from "../../store/actions/empresaActions";
import {
  setMonthAction,
  setYearAction,
} from "../../store/actions/configActions";
import moment from "moment";
import { useRequest } from "../../hooks/useRequest";
import { useVenta } from "../../hooks/useVenta";
import { CREATE, UPDATE, DELETE } from "../../store/types";
import { useModal } from "../../hooks/useModal";
import FormModal from "../../components/organisms/FormModal";
import { useSnackbar } from "notistack";
import NumberFormat from "react-number-format";
import CodigoControl from "../../components/atoms/CodigoControl";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";
import PublishIcon from "@material-ui/icons/Publish";
import SignsIcon from "@material-ui/icons/Exposure";
import DownloadIcon from "@material-ui/icons/GetApp";
import { reportPDFVentas } from "../../reports/ventasPDF";
import { reportExcelVentas } from "../../reports/ventasExcel";
import PdfModal from "../../components/organisms/PdfModal";
import { TotalesVenta } from "../../components/organisms/Totales";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useRefs } from "../../hooks/useRefs";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import { COMPUTARIZADA, INICIAL, SIMPLE } from "../../store/types/comprasTypes";
import CSVReader from "../../components/organisms/CSVReader";

const Ventas = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [operation, setOperation] = useState(CREATE);
  const { open, setOpen } = useModal(() => {});
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const { month, year } = useSelector(({ configReducer }) => configReducer);
  const { venta, setVenta, resetVenta, tipos } = useVenta(
    `${year}-${month}-01`,
    empresa
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [totalesOpen, setTotalesOpen] = useState(false);
  const { addRef, nextFocus, prevFocus, setIndex, startFocus } = useRefs(18);
  const [anchorElShorcut, setAnchorElShorcut] = useState(null);
  const { open: openCSV, setOpen: setOpenCSV } = useModal(() => {});

  useEffect(() => {
    setVenta({
      ...venta,
      fecha: `${year}-${month}-${venta.day}`,
    });
  }, [month, year]);

  const { request: requestVentas, loading } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/ventas`,
    "get",
    (resp) => {
      dispatch(setVentasAction(resp?.ventas));
      dispatch(setTotalesVentasAction(resp?.totales));
    }
  );

  const { request: requestPost } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/ventas`,
    "post",
    () => {
      enqueueSnackbar("Venta registrada exitosamente", {
        variant: "success",
      });
      const token = localStorage.getItem("token");
      requestVentas(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      resetVenta(true);
      startFocus();
    },
    () => enqueueSnackbar("Factura de venta duplicada", { variant: "error" })
  );

  const { request: requestPut } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/ventas/${venta?.id_venta}`,
    "put",
    () => {
      enqueueSnackbar("Venta actualizada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestVentas(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      resetVenta();
    },
    () => enqueueSnackbar("Codigo de sucursal duplicado", { variant: "error" })
  );

  const { request: requestDelete } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/ventas/${venta.id_venta}`,
    "delete",
    () => {
      enqueueSnackbar("Venta eliminada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestVentas(
        "params",
        {
          inicio: `${year}-${month}-01`,
          fin: `${year}-${month}-${moment(
            `${year}-${month}`,
            "YYYY-MM"
          ).daysInMonth()}`,
        },
        token
      );
      resetVenta();
    }
  );

  const { request: requestVenta } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${empresa?.sucursal}/ventas/-1`,
    "get",
    ({ razon_social = "" }) => {
      setVenta({ ...venta, razon_social });
    }
  );

  const handleSubmit = (funcOnFubmit) => {
    if (true) {
      funcOnFubmit();
    }
  };

  const onSubmit = (data) => {
    const token = localStorage.getItem("token");
    if (operation === CREATE) {
      requestPost("data", { ...venta, id_empresa: empresa.id_empresa }, token);
    } else if (operation === UPDATE) {
      requestPut("data", { ...venta, id_empresa: empresa.id_empresa }, token);
    } else if (operation === DELETE) {
      requestDelete("", {}, token);
    }
  };

  const onClose = (e, r) => {
    if (!r) {
      setOpen(false);
      resetVenta();
    }
  };

  const prepareAction = (type, venta) => {
    setOperation(type);
    setOpen(true);
    setVenta({ ...venta, day: moment(venta.fecha, "YYYY-MM-DD").date() });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    requestVentas(
      "params",
      {
        inicio: `${year}-${month}-01`,
        fin: `${year}-${month}-${moment(
          `${year}-${month}`,
          "YYYY-MM"
        ).daysInMonth()}`,
      },
      token
    );
  }, [year, month, empresa.sucursal]);

  const handleOnBlurCurrencys = (event) => {
    const { name, value } = event.target;
    if (name === "codigo_control" && !value) {
      setVenta({ ...venta, codigo_control: "0" });
    } else if (!value) {
      setVenta({ ...venta, [name]: "0.00" });
    }
    event.preventDefault();
  };

  const handleEnter = (key, event) => {
    if (key === "enter") {
      nextFocus();
    } else if (key === "shift+enter") {
      prevFocus();
    }
    event.preventDefault();
  };

  return (
    <>
      <MainApp>
        <OptionsApp>
          <div className={classes.header}>
            <Typography variant="h6" align="center">
              Ventas -
              <select
                className="month-select"
                name="month"
                id="month"
                value={month}
                onChange={(e) => dispatch(setMonthAction(e.target.value))}
              >
                <option value="01">Enero</option>
                <option value="02">Febrero</option>
                <option value="03">Marzo</option>
                <option value="04">Abril</option>
                <option value="05">Mayo</option>
                <option value="06">Junio</option>
                <option value="07">Julio</option>
                <option value="08">Agosto</option>
                <option value="09">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>{" "}
              -
              <input
                className="year-input"
                type="number"
                value={year}
                onChange={(e) => dispatch(setYearAction(e.target.value))}
              />
            </Typography>
            <IconButton
              className={classes.addBtn}
              onClick={() => {
                setOpen(true);
                setOperation(CREATE);
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              className={classes.optionsBtn}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setPdf(reportPDFVentas(empresa, { month, year }));
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <PrintIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Imprimir" />
              </MenuItem>
              {empresa?.ventas.length > 0 && (
                <div>
                  <MenuItem
                    onClick={() => {
                      setTotalesOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <SignsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Totales" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      reportExcelVentas(
                        empresa?.ventas,
                        empresa?.nit,
                        `${month}${year}`
                      );
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <DownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Descargar" />
                  </MenuItem>
                </div>
              )}

              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setOpenCSV(true);
                }}
              >
                <ListItemIcon>
                  <PublishIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Cargar CSV" />
              </MenuItem>
            </Menu>
          </div>
        </OptionsApp>
        <BodyApp>
          {empresa.ventas.length ? (
            <TableVentas prepareAction={prepareAction} />
          ) : (
            <EmptyRecords loading={loading} />
          )}
        </BodyApp>
      </MainApp>
      <FormModal open={open} handleClose={onClose} width="md">
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          className={classes.shorcutForm}
          onClick={(e) => setAnchorElShorcut(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorElShorcut}
          keepMounted
          open={Boolean(anchorElShorcut)}
          onClose={() => setAnchorElShorcut(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography gutterBottom variant="body2" align="center">
            <b>TIPOS</b>
          </Typography>
          <MenuItem
            selected={tipos.tipo === INICIAL}
            onClick={() => {
              tipos.changeTipo(INICIAL);
              setAnchorElShorcut(null);
            }}
          >
            <ListItemIcon>
              <DynamicFeedIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Completo" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(SIMPLE);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === SIMPLE}
          >
            <ListItemIcon>
              <FlashOnIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Simple" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              tipos.changeTipo(COMPUTARIZADA);
              setAnchorElShorcut(null);
            }}
            selected={tipos.tipo === COMPUTARIZADA}
          >
            <ListItemIcon>
              <ClosedCaptionIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Computarizada" />
          </MenuItem>
        </Menu>
        <FormModal.Title>FORMULARIO VENTAS</FormModal.Title>
        <FormModal.Body dividers>
          <KeyboardEventHandler
            handleKeys={["enter", "shift+enter", "ctrl+enter"]}
            onKeyEvent={handleEnter}
          >
            <form
              className={classes.form}
              noValidate
              onSubmit={() => handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="tipo_venta_label">VENTA</InputLabel>
                    <Select
                      fullWidth
                      labelId="tipo_venta_label"
                      id="tipo_venta"
                      value={venta.estado}
                      onChange={(e) =>
                        setVenta({ ...venta, estado: e.target.value })
                      }
                      label="ESTADO"
                    >
                      <MenuItem value={"A"}>ANULADA</MenuItem>
                      <MenuItem value={"V"}>VALIDA</MenuItem>
                      <MenuItem value={"C"}>EMITIDA EN CONTINGENCIA</MenuItem>
                      <MenuItem value={"L"}>LIBRE CONSIGNACIÓN</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="tipo_venta_label">TIPO DE VENTA</InputLabel>
                    <Select
                      fullWidth
                      labelId="tipo_venta_label"
                      id="tipo_venta"
                      value={venta.tipo_venta}
                      onChange={(e) =>
                        setVenta({ ...venta, tipo_venta: e.target.value })
                      }
                      label="TIPO DE VENTA"
                    >
                      <MenuItem value={"0"}>OTROS</MenuItem>
                      <MenuItem value={"1"}>GIFT CARD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.day}
                    onValueChange={(values, sourceInfo) => {
                      setVenta({
                        ...venta,
                        fecha: moment(
                          values.formattedValue,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD"),
                        day: values.value,
                      });
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="FECHA"
                    autoFocus
                    inputProps={{ style: { textAlign: "left" } }}
                    displayType="input"
                    type="text"
                    inputRef={(ref) => addRef(ref, 0)}
                    onFocus={(e) => {
                      setIndex(0);
                      e.target.select();
                    }}
                    suffix={`/${month}/${year}`}
                    allowNegative={false}
                    decimalScale={0}
                    isNumericString={true}
                    allowLeadingZeros={true}
                    allowEmptyFormatting={false}
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.nit}
                    onValueChange={(values, sourceInfo) => {
                      setVenta({ ...venta, nit: values.value });
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="NIT"
                    inputProps={{ style: { textAlign: "center" } }}
                    displayType="input"
                    type="text"
                    inputRef={(ref) => addRef(ref, 1)}
                    onFocus={(e) => {
                      setIndex(1);
                      e.target.select();
                    }}
                    allowNegative={false}
                    decimalScale={0}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    onBlur={() => {
                      const token = localStorage.getItem("token");
                      requestVenta("params", { nit: venta.nit }, token);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    fullWidth
                    value={venta.complemento}
                    onChange={(e) =>
                      setVenta({
                        ...venta,
                        complemento: e.target.value,
                      })
                    }
                    inputRef={(ref) => addRef(ref, 2)}
                    onFocus={(e) => {
                      setIndex(2);
                      e.target.select();
                    }}
                    label="EXP"
                    variant="outlined"
                    placeholder="LP"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={venta.razon_social}
                    onChange={(e) =>
                      setVenta({
                        ...venta,
                        razon_social: e.target.value?.toUpperCase(),
                      })
                    }
                    inputRef={(ref) => addRef(ref, 3)}
                    onFocus={(e) => {
                      setIndex(3);
                      e.target.select();
                    }}
                    label="RAZON SOCIAL"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.factura}
                    onValueChange={(values, sourceInfo) => {
                      setVenta({ ...venta, factura: values.value });
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="NUMERO DE FACTURA"
                    inputProps={{ style: { textAlign: "center" } }}
                    displayType="input"
                    type="text"
                    inputRef={(ref) => addRef(ref, 4)}
                    onFocus={(e) => {
                      setIndex(4);
                      e.target.select();
                    }}
                    allowNegative={false}
                    decimalScale={0}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    value={venta.autorizacion}
                    onChange={(e) =>
                      setVenta({
                        ...venta,
                        autorizacion: e.target.value,
                      })
                    }
                    inputRef={(ref) => addRef(ref, 5)}
                    onFocus={(e) => {
                      setIndex(5);
                      e.target.select();
                    }}
                    label="N AUTORIZACION"
                    variant="outlined"
                  />
                </Grid>
                {tipos.fields.codigo_control.show && (
                  <Grid item xs={3}>
                    <TextField
                      label="CODIGO DE CONTROL"
                      value={venta.codigo_control}
                      onChange={(e) =>
                        setVenta({ ...venta, codigo_control: e.target.value })
                      }
                      InputProps={{
                        inputComponent: CodigoControl,
                        inputRef: (ref) => addRef(ref, 6),
                      }}
                      InputLabelProps={{
                        shrink: venta?.codigo_control.length !== 0,
                      }}
                      name="codigo_control"
                      onFocus={(e) => {
                        setIndex(6);
                        e.target.select();
                      }}
                      onBlur={handleOnBlurCurrencys}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.importe}
                    onValueChange={({ value: importe }, sourceInfo) => {
                      setVenta({ ...venta, importe });
                    }}
                    customInput={TextField}
                    inputProps={{ style: { textAlign: "right" } }}
                    variant="outlined"
                    label="IMPORTE TOTAL"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    inputRef={(ref) => addRef(ref, 7)}
                    onFocus={(e) => {
                      setIndex(7);
                      e.target.select();
                    }}
                    name="importe"
                    onBlur={handleOnBlurCurrencys}
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                {tipos.fields.ice.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.ice}
                      onValueChange={({ value: ice }, sourceInfo) => {
                        setVenta({ ...venta, ice });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="ICE"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 8)}
                      onFocus={(e) => {
                        setIndex(8);
                        e.target.select();
                      }}
                      name="ice"
                      onBlur={handleOnBlurCurrencys}
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.iehd.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.iehd}
                      onValueChange={({ value: iehd }, sourceInfo) => {
                        setVenta({ ...venta, iehd });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="IEHD"
                      inputRef={(ref) => addRef(ref, 9)}
                      onFocus={(e) => {
                        setIndex(9);
                        e.target.select();
                      }}
                      name="iehd"
                      onBlur={handleOnBlurCurrencys}
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.ipj.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.ipj}
                      onValueChange={({ value: ipj }, sourceInfo) => {
                        setVenta({ ...venta, ipj });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="IPJ"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      inputRef={(ref) => addRef(ref, 10)}
                      onFocus={(e) => {
                        setIndex(10);
                        e.target.select();
                      }}
                      name="ipj"
                      onBlur={handleOnBlurCurrencys}
                      type="text"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.tasas.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.tasas}
                      onValueChange={({ value: tasas }, sourceInfo) => {
                        setVenta({ ...venta, tasas });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="TASAS"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 11)}
                      onFocus={(e) => {
                        setIndex(11);
                        e.target.select();
                      }}
                      name="tasas"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.otros_no_dfiva.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.otros_no_dfiva}
                      onValueChange={(
                        { value: otros_no_dfiva },
                        sourceInfo
                      ) => {
                        setVenta({ ...venta, otros_no_dfiva });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="NO SUJETO A DFIVA"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 12)}
                      onFocus={(e) => {
                        setIndex(12);
                        e.target.select();
                      }}
                      name="otros_no_dfiva"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.exportaciones.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.exportaciones}
                      onValueChange={({ value: exportaciones }, sourceInfo) => {
                        setVenta({ ...venta, exportaciones });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="EXPORTACIONES"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 13)}
                      onFocus={(e) => {
                        setIndex(13);
                        e.target.select();
                      }}
                      name="exportaciones"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.tasa_cero.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.tasa_cero}
                      onValueChange={({ value: tasa_cero }, sourceInfo) => {
                        setVenta({ ...venta, tasa_cero });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="TASA CERO"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 14)}
                      onFocus={(e) => {
                        setIndex(14);
                        e.target.select();
                      }}
                      name="tasa_cero"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.subtotal}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="SUBTOTAL"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                {tipos.fields.descuentos.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.descuentos}
                      onValueChange={({ value: descuentos }, sourceInfo) => {
                        setVenta({ ...venta, descuentos });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="DESCUENTOS"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 15)}
                      onFocus={(e) => {
                        setIndex(15);
                        e.target.select();
                      }}
                      name="descuentos"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                {tipos.fields.gift_card.show && (
                  <Grid item xs={3}>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={venta.gift_card}
                      onValueChange={({ value: gift_card }, sourceInfo) => {
                        setVenta({ ...venta, gift_card });
                      }}
                      customInput={TextField}
                      inputProps={{ style: { textAlign: "right" } }}
                      variant="outlined"
                      label="GIFT CARD"
                      fullWidth
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      inputRef={(ref) => addRef(ref, 16)}
                      onFocus={(e) => {
                        setIndex(16);
                        e.target.select();
                      }}
                      name="gift_card"
                      onBlur={handleOnBlurCurrencys}
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      isNumericString={true}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={false}
                      fixedDecimalScale={true}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.base_dfiva}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="BASE DFIVA"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={venta.dfiva}
                    customInput={TextField}
                    inputProps={{
                      className: classes.inputReadOnly,
                      readOnly: true,
                      style: { textAlign: "right" },
                    }}
                    variant="outlined"
                    label="DFIVA"
                    fullWidth
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    isNumericString={true}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    fixedDecimalScale={true}
                  />
                </Grid>
              </Grid>
            </form>
          </KeyboardEventHandler>
        </FormModal.Body>
        <FormModal.Actions>
          <Button
            onClick={() => handleSubmit(onSubmit)}
            color="primary"
            type="submit"
            ref={(ref) => addRef(ref, 17)}
          >
            {operation}
          </Button>
          <Button onClick={onClose} color="primary">
            CANCELAR
          </Button>
        </FormModal.Actions>
      </FormModal>
      <PdfModal
        pdf={pdf}
        open={Boolean(pdf)}
        handleClose={() => setPdf(null)}
      />
      <FormModal
        open={totalesOpen}
        handleClose={(e, r) => {
          if (!r) setTotalesOpen(false);
        }}
      >
        <FormModal.Title>TOTALES</FormModal.Title>
        <FormModal.Body dividers>
          <TotalesVenta totales={empresa?.totalesVentas} />
        </FormModal.Body>
        <FormModal.Actions>
          <Button onClick={() => setTotalesOpen(false)} color="primary">
            CERRAR
          </Button>
        </FormModal.Actions>
      </FormModal>
      <CSVReader
        tipo="ventas"
        open={openCSV}
        handleClose={() => setOpenCSV(false)}
        onSuccess={() => {
          const token = localStorage.getItem("token");
          requestVentas(
            "params",
            {
              inicio: `${year}-${month}-01`,
              fin: `${year}-${month}-${moment(
                `${year}-${month}`,
                "YYYY-MM"
              ).daysInMonth()}`,
            },
            token
          );
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    width: "100%",
  },
  addBtn: {
    position: "absolute",
    right: 0,
    top: -5,
  },
  optionsBtn: {
    position: "absolute",
    left: 0,
    top: -5,
  },
  shorcutForm: {
    position: "absolute",
    right: 0,
    top: 5,
  },
  inputReadOnly: {
    backgroundColor: "#FFF2CC",
  },
}));

export default Ventas;
