import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { currencyFormat } from '../utils/currency';
import { curtText } from '../utils/text';

export const reportPDFCompras = (empresa = {}, config = {}) => {
  const sucursal = empresa?.sucursales?.find(sucursal => sucursal.id_sucursal === empresa?.sucursal );
  const pdf = new jsPDF('landscape', 'mm', [330, 215.9]);

  const rowsPerPage = 27;
  const numberPages = Math.ceil(empresa?.compras?.length / rowsPerPage);
  const pagesCompras = [];
  const totales = [];
  const parciales = [];
  // partir en paginas las compras
  for (let j = 0; j < numberPages; j++) {
    pagesCompras.push(empresa?.compras?.slice(j*rowsPerPage, j*rowsPerPage+rowsPerPage));
  }
  // sacar los totales por pagina
  for (let z = 0; z < numberPages; z++) {
    const totalPage = pagesCompras[z].reduce((a,b)=>{
      return {
        importe:(parseFloat(a.importe) +parseFloat(b.importe)).toFixed(2),
        ice:(parseFloat(a.ice) +parseFloat(b.ice)).toFixed(2),
        iehd:(parseFloat(a.iehd) +parseFloat(b.iehd)).toFixed(2),
        ipj:(parseFloat(a.ipj) +parseFloat(b.ipj)).toFixed(2),
        tasas:(parseFloat(a.tasas) +parseFloat(b.tasas)).toFixed(2),
        otros_no_cfiva:(parseFloat(a.otros_no_cfiva) +parseFloat(b.otros_no_cfiva)).toFixed(2),
        exentos:(parseFloat(a.exentos) +parseFloat(b.exentos)).toFixed(2),
        tasa_cero:(parseFloat(a.tasa_cero) +parseFloat(b.tasa_cero)).toFixed(2),
        subtotal:(parseFloat(a.subtotal) +parseFloat(b.subtotal)).toFixed(2),
        descuentos:(parseFloat(a.descuentos) +parseFloat(b.descuentos)).toFixed(2),
        gift_card:(parseFloat(a.gift_card) +parseFloat(b.gift_card)).toFixed(2),
        base_cfiva:(parseFloat(a.base_cfiva) +parseFloat(b.base_cfiva)).toFixed(2),
        cfiva:(parseFloat(a.cfiva) +parseFloat(b.cfiva)).toFixed(2),
        }
      }
    );
    totales.push(totalPage);
  }

  // sacar los parciales por pagina
  parciales.push({...totales[0]});
  for (let i = 1; i < numberPages; i++) {
    parciales.push({
      importe: (parseFloat(parciales[i-1].importe) + parseFloat(totales[i].importe)).toFixed(2),
      ice: (parseFloat(parciales[i-1].ice) + parseFloat(totales[i].ice)).toFixed(2),
      iehd: (parseFloat(parciales[i-1].iehd) + parseFloat(totales[i].iehd)).toFixed(2),
      ipj: (parseFloat(parciales[i-1].ipj) + parseFloat(totales[i].ipj)).toFixed(2),
      tasas: (parseFloat(parciales[i-1].tasas) + parseFloat(totales[i].tasas)).toFixed(2),
      otros_no_cfiva: (parseFloat(parciales[i-1].otros_no_cfiva) + parseFloat(totales[i].otros_no_cfiva)).toFixed(2),
      exentos: (parseFloat(parciales[i-1].exentos) + parseFloat(totales[i].exentos)).toFixed(2),
      tasa_cero: (parseFloat(parciales[i-1].tasa_cero) + parseFloat(totales[i].tasa_cero)).toFixed(2),
      subtotal: (parseFloat(parciales[i-1].subtotal) + parseFloat(totales[i].subtotal)).toFixed(2),
      descuentos: (parseFloat(parciales[i-1].descuentos) + parseFloat(totales[i].descuentos)).toFixed(2),
      gift_card: (parseFloat(parciales[i-1].gift_card) + parseFloat(totales[i].gift_card)).toFixed(2),
      base_cfiva: (parseFloat(parciales[i-1].base_cfiva) + parseFloat(totales[i].base_cfiva)).toFixed(2),
      cfiva: (parseFloat(parciales[i-1].cfiva) + parseFloat(totales[i].cfiva)).toFixed(2),
    });
  }
  

  const headerPDF = () => {
    pdf.setFont("Lato", "bold");
    pdf.setFontSize(16)
    pdf.text(130, 10, 'REGISTRO DE COMPRAS');
    pdf.setFontSize(11)
    pdf.text(150, 15, `${config.month}/${config.year}`);

    pdf.setFontSize(12);
    pdf.setFont("Lato", "bold");
    pdf.text(10, 22, "NIT: ");
    pdf.setFont("Lato", "normal");
    pdf.text(20, 22, empresa.nit);
    pdf.setFont("Lato", "bold");
    pdf.text(50, 22, "RAZON SOCIAL: ");
    pdf.setFont("Lato", "normal");
    pdf.text(85, 22, curtText(empresa.razon_social, 35));
    pdf.setFont("Lato", "bold");
    pdf.text(180, 22, "SUCURSAL: ");
    pdf.setFont("Lato", "normal");
    pdf.text(205, 22, curtText(`${sucursal?.codigo} - ${sucursal?.zona} - ${sucursal?.direccion}`, 50));
  }
  const bodyPDF = (pageNumber = 1) => {
    pdf.autoTable({
      head: headerTable(),
      body: bodyTable(pageNumber),
      foot: footerTable(pageNumber),
      startY: 25,
      theme: 'grid',
      margin: { top: 0, left: 5, bottom: 0, right: 5 },
      headStyles,
      bodyStyles,
      footStyles,
      columnStyles
    });
  }
  const footerPDF = (pageNumber = 1) => {
    pdf.setFontSize(10);
    pdf.setFont("Lato", "bold");
    pdf.text(10, 209, "TITULAR: ");
    pdf.setFont("Lato", "normal");
    pdf.text(30, 209, curtText(empresa.responsable, 35))
    pdf.setFont("Lato", "bold");
    pdf.text(120, 209, "CI: ")
    pdf.setFont("Lato", "normal");
    pdf.text(130, 209, empresa.ci)
    if(numberPages > 1) {
      pdf.text(300, 209, `PÁGINA ${pageNumber+1}/${numberPages}`);
    }
  }

  const headerTable = () => (
    [
      [
        'N',
        'FECHA',
        'NIT',
        'RAZON SOCIAL',
        'FACTURA',
        'DUI',
        'AUTORIZACION',
        'TOTAL',
        'ICE',
        'IEHD',
        'IPJ',
        'TASAS',
        'NO CFIVA',
        'EXENTOS',
        'TASA CERO',
        'SUBTOTAL',
        "DESCUENTOS",
        "GIFT CARD",
        'BASE CFIVA',
        'CFIVA',
        'TIPO',
        'CODIGO CONTROL'
      ]
    ]
  );

  const bodyTable = (pageNumber = 1) => {
    const body = [];
    pagesCompras[pageNumber]?.forEach((compra, index) => {
      body.push([
        { content: index + 1 + rowsPerPage * pageNumber },
        { content: moment(compra.fecha, "YYYY-MM-DD").format("DD/MM/YYYY") },
        { content: compra.nit, styles: { halign: 'right' } },
        { content: curtText(compra.razon_social, 22), styles: { halign: 'left' } },
        { content: compra.factura, styles: { halign: 'right' } },
        { content: compra.dui, styles: { halign: 'right' } },
        { content: curtText(compra.autorizacion, 18), styles: { halign: 'right' } },
        { content: currencyFormat(compra.importe), styles: { halign: 'right' } },
        { content: currencyFormat(compra.ice), styles: { halign: 'right' } },
        { content: currencyFormat(compra.iehd), styles: { halign: 'right' } },
        { content: currencyFormat(compra.ipj), styles: { halign: 'right' } },
        { content: currencyFormat(compra.tasas), styles: { halign: 'right' } },
        { content: currencyFormat(compra.otros_no_cfiva), styles: { halign: 'right' } },
        { content: currencyFormat(compra.exentos), styles: { halign: 'right' } },
        { content: currencyFormat(compra.tasa_cero), styles: { halign: 'right' } },
        { content: currencyFormat(compra.subtotal), styles: { halign: 'right' } },
        { content: currencyFormat(compra.descuentos), styles: { halign: 'right' } },
        { content: currencyFormat(compra.gift_card), styles: { halign: 'right' } },
        { content: currencyFormat(compra.base_cfiva), styles: { halign: 'right' } },
        { content: currencyFormat(compra.cfiva), styles: { halign: 'right' } },
        { content: compra.tipo_compra, styles: { halign: 'right' } },
        { content: compra.codigo_control, styles: { halign: 'right' } }
      ]);
    });

    if(numberPages > 0) {
      // x = 10 - (12 % 10) = 8
      if(pageNumber + 1 === numberPages && empresa?.compras?.length % rowsPerPage !== 0) {
        //  x = 3 - 12 % 3 = 3
        const rowsToAdd = rowsPerPage - (empresa?.compras?.length % rowsPerPage);
        for (let i = 1; i <= rowsToAdd; i++) {
          body.push({ content: '' });
        }
      }
    }else {
      const rowsToAdd = rowsPerPage - (empresa?.compras?.length % rowsPerPage);
        for (let i = 1; i <= rowsToAdd; i++) {
          body.push({ content: '' });
        }
    }
    return body;
  }

  const footerTable = (pageNumber = 1) => {
    let endPage  = pageNumber + 1 === numberPages;
    if(!numberPages){
      endPage = true;
    }
    return [
      [
        { content: 'TOTAL PAGINA',colSpan: 7,styles: { halign: 'right' }},
        { content: currencyFormat(totales[pageNumber]?.importe || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.ice || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.iehd || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.ipj || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.tasas || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.otros_no_cfiva || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.exentos || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.tasa_cero || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.subtotal || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.descuentos || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.gift_card || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.base_cfiva || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(totales[pageNumber]?.cfiva || "0.00"), styles: { halign: 'right' } },
      ],
      [
        {
          content: endPage?'TOTAL COMPRAS' :'TOTAL PARCIAL', colSpan: 7, styles: { halign: 'right' }
        },
        { content: currencyFormat(parciales[pageNumber]?.importe || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.ice || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.iehd || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.ipj || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.tasas || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.otros_no_cfiva || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.exentos || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.tasa_cero || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.subtotal || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.descuentos || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.gift_card || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.base_cfiva || "0.00"), styles: { halign: 'right' } },
        { content: currencyFormat(parciales[pageNumber]?.cfiva || "0.00"), styles: { halign: 'right' } }
      ]
    ];
  }


  for (let i = 0; i < numberPages; i++) {
      // HEADER
    headerPDF();
    // BODY
    bodyPDF(i);
    // FOOTER
    footerPDF(i);

    if(i === numberPages-1) continue;
    pdf.addPage();
  }
  if(numberPages === 0) {
    headerPDF();
    bodyPDF();
    footerPDF();
    pdf.setFontSize(40);
    pdf.setTextColor("Gray");
    pdf.text(100, 115, `SIN MOVIMIENTO`);
  }

  return pdf.output('datauristring');
}

const headStyles = {
  fontSize: 6,
  valign: 'middle',
  halign: 'center',
  fillColor: [0, 0, 0],
  textColor: [255, 255, 255],
  font: "Lato"
};

const bodyStyles = {
  fontSize: 6,
  valign: 'middle',
  halign: 'center',
  font: "Lato",
};

const footStyles = {
  fontSize: 7,
  valign: 'middle',
  halign: 'center',
  fillColor: [0, 0, 0],
  textColor: [255, 255, 255]
};

const columnStyles = {
};