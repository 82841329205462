/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState
} from "react";
import { BOLETO_AVION, COMPUTARIZADA, GASOLINA, IMPORTACION, IMPORTACION_TASA_CERO, INICIAL, SERVICIOS, SIMPLE, TASA_CERO } from "../store/types/comprasTypes";
import { cround } from "../utils/numbers";
import {useTiposCompras} from "./useTiposCompras";

export const compraValues = {
  nit: "",
  razon_social: "",
  autorizacion: "",
  factura: "",
  dui: "0",
  fecha: "",
  importe: "",
  ice: "",
  iehd: "",
  ipj: "",
  tasas: "",
  otros_no_cfiva: "",
  exentos: "",
  tasa_cero: "",
  subtotal: "",
  descuentos: "",
  gift_card: "",
  base_cfiva: "",
  cfiva: "",
  codigo_control: "",
  tipo_compra: "1",
  id_sucursal: "",
  day: "01",
  rubro: "",
};

export const useCompra = (fecha, empresa) => {
  const [defaultValues, setDefaultValues] = useState({
    ...compraValues,
    fecha,
    id_sucursal: empresa.sucursal
  });
  const [compra, setCompra] = useState({
    ...compraValues,
    fecha,
    id_sucursal: empresa.sucursal
  });

  const {tipo, fields, changeTipo} = useTiposCompras();

  useEffect(()=>{
    setCompra({
      ...compra,
      factura: fields.factura.valueDefault,
      ...(!!fields.autorizacion.valueDefault && {autorizacion: fields.autorizacion.valueDefault}),
      dui: fields.dui.valueDefault,
      ice: fields.ice.valueDefault,
      iehd: fields.iehd.valueDefault,
      ipj: fields.ipj.valueDefault,
      otros_no_cfiva: fields.otros_no_cfiva.valueDefault,
      tasas: fields.tasas.valueDefault,
      tasa_cero: fields.tasa_cero.valueDefault,
      exentos: fields.exentos.valueDefault,
      gift_card: fields.gift_card.valueDefault,
      descuentos: fields.descuentos.valueDefault,
      codigo_control: fields.codigo_control.valueDefault
    })
    setDefaultValues({
      ...compra,
      factura: fields.factura.valueDefault,
      ...(!!fields.autorizacion.valueDefault && {autorizacion: fields.autorizacion.valueDefault}),
      dui: fields.dui.valueDefault,
      ice: fields.ice.valueDefault,
      iehd: fields.iehd.valueDefault,
      ipj: fields.ipj.valueDefault,
      otros_no_cfiva: fields.otros_no_cfiva.valueDefault,
      tasas: fields.tasas.valueDefault,
      tasa_cero: fields.tasa_cero.valueDefault,
      exentos: fields.exentos.valueDefault,
      gift_card: fields.gift_card.valueDefault,
      descuentos: fields.descuentos.valueDefault,
      codigo_control: fields.codigo_control.valueDefault
    })
  }, [fields]);

  useEffect(() => {
    setCompra({
      ...compra,
      id_sucursal: empresa?.sucursal
    });
  }, [empresa?.sucursal]);

  useEffect(() => {
    const {
      importe,
      ice,
      iehd,
      ipj,
      tasas,
      otros_no_cfiva,
      exentos,
      tasa_cero
    } = compra;
    if([INICIAL, IMPORTACION, SIMPLE, COMPUTARIZADA, SERVICIOS, BOLETO_AVION].includes(tipo)) {
      let subtotal = importe - ice - iehd - ipj - tasas - otros_no_cfiva - exentos - tasa_cero;
      subtotal = cround(subtotal);
      setCompra({
        ...compra,
        subtotal
      });
    } else if (tipo === GASOLINA) {
      let otros_no_cfiva = importe  * 0.30;
      otros_no_cfiva = cround(otros_no_cfiva);
      let subtotal = importe - otros_no_cfiva;
      subtotal = cround(subtotal);
      setCompra({
        ...compra,
        otros_no_cfiva,
        subtotal
      });
    } else if ([IMPORTACION_TASA_CERO, TASA_CERO].includes(tipo)) {
      let tasacero = importe;
      const subtotal = cround(importe - tasacero);
      setCompra({
        ...compra,
        tasa_cero: tasacero,
        subtotal
      });
    }
  }, [
    compra.importe,
    compra.ice,
    compra.iehd,
    compra.ipj,
    compra.tasas,
    compra.otros_no_cfiva,
    compra.exentos,
    compra.tasa_cero
  ]);

  useEffect(() => {
    const {
      subtotal,
      descuentos,
      gift_card
    } = compra;
    let base_cfiva = subtotal - descuentos - gift_card;
    base_cfiva = cround(base_cfiva);
    let cfiva = cround(base_cfiva * 0.13);
    setCompra({
      ...compra,
      base_cfiva,
      cfiva
    })
  }, [
    compra.subtotal,
    compra.descuentos,
    compra.gift_card,
  ]);

  const resetCompra = () => {
    setCompra({
      ...defaultValues,
      id_sucursal: empresa.sucursal,
      fecha: compra.fecha,
      day: compra.day,
      nit: "",
      razon_social: "",
      autorizacion: "",
      importe: "",
      subtotal: "",
      base_cfiva: "",
      cfiva: "",
      rubro: "",
    });
  };

  return {
    compra,
    setCompra,
    resetCompra,
    tipos: {
      fields, changeTipo, tipo
    }
  };
};