/* eslint-disable react-hooks/exhaustive-deps */
import MainApp from "../../components/templates/layout/MainApp";
import OptionsApp from "../../components/templates/layout/OptionsApp";
import BodyApp from "../../components/templates/layout/BodyApp";
import { Typography, IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import TableSucursales from "./TableSucursales";
import FormModal from "../../components/organisms/FormModal";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "../../hooks/useRequest";
import { useSnackbar } from "notistack";
import { useModal } from "../../hooks/useModal";
import { setSucursalesAction } from "../../store/actions/empresaActions";
import { useState } from "react";
import { CREATE, UPDATE, DELETE } from "../../store/types";

const sucursalValues = {
  id_sucursal: "",
  id_empresa: "",
  codigo: "",
  zona: "",
  direccion: "",
};

const Sucursales = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [sucursal, setSucursal] = useState({ ...sucursalValues });
  const [operation, setOperation] = useState(CREATE);
  const empresa = useSelector(({ empresaReducer }) => empresaReducer);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ sucursalValues });
  const { open, setOpen } = useModal(() => reset({ ...sucursalValues }));

  const { request: requestSucursales } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales`,
    "get",
    (resp) => dispatch(setSucursalesAction(resp))
  );

  const { request: requestPost } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales`,
    "post",
    () => {
      enqueueSnackbar("Sucursal registrada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestSucursales("", {}, token);
    },
    () => enqueueSnackbar("Codigo de sucursal duplicado", { variant: "error" })
  );

  const { request: requestPut } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${sucursal?.id_sucursal}`,
    "put",
    () => {
      enqueueSnackbar("Sucursal actualizada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      const token = localStorage.getItem("token");
      requestSucursales("", {}, token);
    },
    () => enqueueSnackbar("Codigo de sucursal duplicado", { variant: "error" })
  );

  const { request: requestDelete } = useRequest(
    `/empresas/${empresa?.id_empresa}/sucursales/${sucursal?.id_sucursal}`,
    "delete",
    () => {
      enqueueSnackbar("Sucursal eliminada exitosamente", {
        variant: "success",
      });
      setOpen(false);
      requestSucursales();
    }
  );

  const onSubmit = (data) => {
    const token = localStorage.getItem("token");
    if (operation === CREATE) {
      requestPost("data", { ...data, id_empresa: empresa.id_empresa }, token);
    } else if (operation === UPDATE) {
      requestPut("data", { ...data, id_empresa: empresa.id_empresa }, token);
    } else if (operation === DELETE) {
      requestDelete();
    }
  };

  const onClose = (e, r) => {
    if (!r) setOpen(false);
  };

  const prepareAction = (type, sucursal) => {
    setOperation(type);
    setOpen(true);
    reset({ ...sucursal });
    setSucursal({ ...sucursal });
  };

  return (
    <>
      <MainApp>
        <OptionsApp>
          <div className={classes.header}>
            <Typography variant="h6" align="center">
              Sucursales
            </Typography>
            <IconButton
              className={classes.addBtn}
              onClick={() => {
                setOpen(true);
                setOperation(CREATE);
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </OptionsApp>
        <BodyApp>
          <TableSucursales prepareAction={prepareAction} />
        </BodyApp>
      </MainApp>
      <FormModal open={open} handleClose={onClose}>
        <FormModal.Title>FORMULARIO SUCURSAL</FormModal.Title>
        <FormModal.Body dividers>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Controller
                  name="codigo"
                  control={control}
                  rules={{ required: true, pattern: /[0-9]+/i }}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      name="codigo"
                      variant="outlined"
                      required
                      inputProps={{ readOnly: operation === DELETE }}
                      fullWidth
                      id="codigo"
                      label="Codigo"
                      autoFocus
                      error={!!errors.codigo}
                      helperText={
                        errors.codigo?.type === "required"
                          ? "Este Campo es requerido"
                          : errors.codigo?.type === "pattern"
                          ? "Solo ingresar numeros"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <Controller
                  name="zona"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      name="zona"
                      variant="outlined"
                      required
                      inputProps={{ readOnly: operation === DELETE }}
                      fullWidth
                      id="zona"
                      label="Zona/Lugar"
                      error={!!errors.zona}
                      helperText={!!errors.zona && "Este Campo es requerido"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="direccion"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      name="direccion"
                      variant="outlined"
                      required
                      inputProps={{ readOnly: operation === DELETE }}
                      fullWidth
                      id="direccion"
                      label="Dirección"
                      error={!!errors.direccion}
                      helperText={
                        !!errors.direccion && "Este Campo es requerido"
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormModal.Body>
        <FormModal.Actions>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            type="submit"
          >
            {operation}
          </Button>
          <Button onClick={onClose} color="primary">
            CANCELAR
          </Button>
        </FormModal.Actions>
      </FormModal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    width: "100%",
  },
  addBtn: {
    position: "absolute",
    right: 0,
    top: -5,
  },
  optionsBtn: {
    position: "absolute",
    left: 0,
    top: -5,
  },
}));

export default Sucursales;
