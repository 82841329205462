import { useState } from "react";
import api from "../services";

export const useRequest = (
  url,
  method = "get",
  funcSuccess = () => {},
  funcError = () => {}
) => {
  const [resp, setResp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const request = (type = "data", payload = {}, token = "") => {
    setLoading(true);
    api
      .request({
        url,
        method,
        [type]: payload,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setResp(response.data);
        funcSuccess(response.data);
      })
      .catch((err) => {
        setError(err);
        funcError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    resp,
    loading,
    error,
    request,
  };
};
