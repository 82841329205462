import './styles.css';

const OptionsApp = ({ children }) => {
  return (
    <div className="options-app center">
      {children}
    </div>
  );
}

export default OptionsApp;