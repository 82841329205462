import XLSX from "xlsx";
import moment from "moment";
import { cround } from "../utils/numbers";

export const reportExcelComprasRubro = (compras, nit, periodo) => {
  const workSheet = XLSX.utils.json_to_sheet(formatExcelCompras(compras));
  sheet_set_column_format(
    workSheet,
    { c: 8, r: 1 },
    { c: 20, r: compras.length }
  );
  workSheet["!margins"]={left:1.0, right:1.0, top:1.0, bottom:1.0, header:0.5,footer:0.5}
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "compras");
  XLSX.write(workBook, {
    bookType: "xlsx",
    type: "binary",
  });
  XLSX.writeFile(workBook, `Compras_Rubro_${nit}_${periodo}.xlsx`);
};

const formatExcelCompras = (compras = []) => {
  const resp = [];
  compras.forEach((compra, index) => {
    resp.push({
      Nº: index + 1,
      ESPECIFICACION: 1,
      "NIT PROVEEDOR": compra.nit,
      "RAZON SOCIAL PROVEEDOR": compra.razon_social,
      "CODIGO DE AUTORIZACION": compra.autorizacion,
      "NUMERO FACTURA": compra.factura,
      "NUMERO DUI/DIM": compra.dui,
      "FECHA DE FACTURA/DUI/DIM": moment(compra.fecha, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      ),
      "IMPORTE TOTAL COMPRA": cround(compra.importe),
      "IMPORTE ICE": cround(compra.ice),
      "IMPORTE IEHD": cround(compra.iehd),
      "IMPORTE IPJ": cround(compra.ipj),
      TASAS: cround(compra.tasas),
      "OTRO NO SUJETO A CREDITO FISCAL": cround(compra.otros_no_cfiva),
      "IMPORTES EXENTOS": cround(compra.exentos),
      "IMPORTE COMPRAS GRAVADAS A TASA CERO": cround(compra.tasa_cero),
      SUBTOTAL: cround(compra.subtotal),
      "DESCUENTOS/BONIFICACIONES /REBAJAS SUJETAS AL IVA": cround(
        compra.descuentos
      ),
      "IMPORTE GIFT CARD": cround(compra.gift_card),
      "IMPORTE BASE CF": cround(compra.base_cfiva),
      "CREDITO FISCAL": cround(compra.cfiva),
      "TIPO COMPRA": compra.tipo_compra,
      "CODIGO DE CONTROL": compra.codigo_control,
      RUBRO: compra.rubro,
    });
  });
  return resp;
};

function sheet_set_column_format(workSheet, firstCell, lastCell) {
  var fmt = "0.00";
  /* change cell format of range B2:D4 */
  var range = { s: firstCell, e: lastCell };
  for (var R = range.s.r; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var cell = workSheet[XLSX.utils.encode_cell({ r: R, c: C })];
      // if (!cell || cell.t != "n") continue; // only format numeric cells
      cell.z = fmt;
    }
  }
}
