import {
  SET_COMPRAS,
  SET_EMPRESA,
  SET_SUCURSAL,
  SET_VENTAS,
  SET_SUCURSALES,
  SET_TOTALES_COMPRAS,
  SET_TOTALES_VENTAS
} from '../types/empresaTypes';

export const setEmpresaAction = empresa => {
  return {
    type: SET_EMPRESA,
    empresa
  };
}

export const setSucursalAction = sucursal => ({
  type: SET_SUCURSAL,
  sucursal
});

export const setSucursalesAction = sucursales => ({
  type: SET_SUCURSALES,
  sucursales
});

export const setComprasAction = compras => ({
  type: SET_COMPRAS,
  compras
});

export const setVentasAction = ventas => ({
  type: SET_VENTAS,
  ventas
});

export const setTotalesVentasAction = totalesVentas => ({
  type: SET_TOTALES_VENTAS,
  totalesVentas
});

export const setTotalesComprasAction = totalesCompras => ({
  type: SET_TOTALES_COMPRAS,
  totalesCompras
});