import {
  SET_MONTH,
  SET_YEAR
} from '../types/configTypes';

export const setMonthAction = month => {
  return {
    type: SET_MONTH,
    month
  };
}

export const setYearAction = year => {
  return {
    type: SET_YEAR,
    year
  };
}