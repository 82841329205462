import MainApp from "../../components/templates/layout/MainApp";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/images/logo.jpeg";

const Inicio = () => {
  const classes = useStyles();
  return (
    <>
      <MainApp>
        <div className={classes.inicio}>
          <img src={logo} alt="" />
        </div>
      </MainApp>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inicio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
  },
}));

export default Inicio;
