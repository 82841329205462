import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { curtText } from "../../utils/text";
import { currencyFormat } from "../../utils/currency";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FacturasList({ facturas = [], tipo = "rechazados" }) {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {facturas.map((factura, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: tipo !== "aceptados" ? "red" : "green",
              }}
            >
              {tipo === "aceptados" ? <CheckCircleIcon /> : <ErrorIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${factura.nit} - ${curtText(factura.razon_social, 50)}`}
            secondary={`FEC:${moment(factura.fecha, "YYYY-MM-DD").format("DD/MM/YYYY")} __ FAC: ${factura.factura} __ AUT: ${curtText(factura.autorizacion, 20)} __ IMP: ${currencyFormat(factura.importe)}`}
          />
        </ListItem>
      ))}
    </List>
  );
}
