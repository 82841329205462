/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState
} from "react";
import {
  INICIAL
} from "../store/types/comprasTypes";




const config = {
  tipo: INICIAL,
  fields: {
    inicial: {
      codigo_control: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: false
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      iehd: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ipj: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      otros_no_cfiva: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      tasas: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      exentos: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      tasa_cero: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      gift_card: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      descuentos: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
    },
    gasolina: {
      codigo_control: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
    importacion: {
      codigo_control: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      factura: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      dui: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      autorizacion: {
        show: true,
        valueDefault: "3",
        readOnly: true
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: false
      },
    },
    importacion_tasa_cero: {
      codigo_control: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      factura: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      dui: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      autorizacion: {
        show: true,
        valueDefault: "3",
        readOnly: true
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: true,
        valueDefault: "",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: false
      },
    },
    simple: {
      codigo_control: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
    computarizada: {
      codigo_control: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
    servicios: {
      codigo_control: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
    boleto_avion: {
      codigo_control: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "1",
        readOnly: true
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
    tasa_cero: {
      codigo_control: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      factura: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      dui: {
        show: false,
        valueDefault: "0",
        readOnly: true
      },
      autorizacion: {
        show: true,
        valueDefault: "",
        readOnly: false
      },
      ice: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      iehd: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      ipj: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      otros_no_cfiva: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasas: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      exentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      tasa_cero: {
        show: true,
        valueDefault: "0.00",
        readOnly: true
      },
      gift_card: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
      descuentos: {
        show: false,
        valueDefault: "0.00",
        readOnly: true
      },
    },
  }
};


export const useTiposCompras = () => {
  const [tipo, setTipo] = useState(config.tipo);
  const [fields, setFields] = useState({
    ...JSON.parse(JSON.stringify(config.fields.inicial))
  });
  const changeTipo = t => setTipo(t);
  useEffect(() => {
    setFields({ ...JSON.parse(JSON.stringify(config.fields[tipo]))})
  }, [tipo]);
  return {
    tipo,
    fields,
    changeTipo
  };
}