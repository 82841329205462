import FormModal from "./FormModal";
import Button from "@material-ui/core/Button";

const PDFModal = ({ pdf, open, handleClose }) => {
  return (
    <>
      <FormModal open={open} handleClose={handleClose} width="xl" fullWidth>
        <FormModal.Body>
          <object
          title="COMPRAS"
            width="100%"
            height="900px"
            type="application/pdf"
            data={pdf}
          >
            <title>some title</title>
            <p>
              Insert your error message here, if the PDF cannot be displayed.
            </p>
          </object>
        </FormModal.Body>
        <FormModal.Actions>
          <Button onClick={handleClose} color="primary">
            CERRAR
          </Button>
        </FormModal.Actions>
      </FormModal>
    </>
  );
};

export default PDFModal;
