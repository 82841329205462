import {
  SET_EMPRESA,
  SET_SUCURSAL,
  SET_COMPRAS,
  SET_VENTAS,
  SET_SUCURSALES,
  SET_TOTALES_COMPRAS,
  SET_TOTALES_VENTAS
} from '../types/empresaTypes';

const initialState = {
  id_empresa: '',
  nit: '',
  razon_social: '',
  responsable: '',
  sucursales: [],
  compras: [],
  ventas: [],
  totalesCompras: {},
  totalesVentas: {},
  sucursal: '',
  ci: ''
};

export const empresaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPRESA:
      return {
        ...state,
        ...action.empresa
      };
    case SET_SUCURSAL:
      return {
        ...state,
        sucursal: action.sucursal
      };
    case SET_COMPRAS:
      return {
        ...state,
        compras: [...action.compras]
      };
    case SET_SUCURSALES:
      return {
        ...state,
        sucursales: [...action.sucursales]
      };
    case SET_VENTAS:
      return {
        ...state,
        ventas: [...action.ventas]
      };
    case SET_TOTALES_COMPRAS:
      return {
        ...state,
        totalesCompras: {
          ...action.totalesCompras
        }
      };
    case SET_TOTALES_VENTAS:
      return {
        ...state,
        totalesVentas: {
          ...action.totalesVentas
        }
      };
    default:
      return state;
  }

}